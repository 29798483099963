import { Injectable } from "@angular/core";
import { BackendRights } from "@ramudden/models/backend-rights";
import { IRole, IRoleWithCategory } from "@ramudden/models/user";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class RolesApi extends ApiService<IRole, void, void> {
    override getRoute(): string {
        return "Roles";
    }

    getRights$(id: string): Observable<BackendRights[]> {
        const url = `${this.getUrl()}/${id}/Rights`;
        return this.http.get<BackendRights[]>(url);
    }

    get(): Observable<IRoleWithCategory[]> {
        return this.http.get<IRoleWithCategory[]>(this.getUrl());
    }
}
