<p-dialog
    (onHide)="onHide()"
    (onShow)="onShow()"
    [(visible)]="visible"
    [appendTo]="appendTo"
    [contentStyle]="contentStyle"
    [dismissableMask]="dismissOnClickOutside"
    [draggable]="draggable"
    [minX]="minX"
    [minY]="minY"
    [modal]="blockBackground"
>
    <p-header>
        {{ (existingJournal ? ( readonly ? "journal.title" : "journal.journalDialogEdit") :
        "journal.journalDialogHeader") | translate:translationObject }}
    </p-header>

    <form
        class="p-16"
        [formGroup]="manageJournalEntryForm"
        *ngIf="manageJournalEntryForm"
        autocomplete="off"
        novalidate
    >
        <div class="row">
            <div class="col-6">
                <div class="m-form-group row">
                    <label
                        class="col-4"
                        for="categoryId"
                    >
                        {{"journal.category" | translate}}
                    </label>

                    <div class="col-8">
                        <p-dropdown
                            id="categoryId"
                            (onChange)="setSelectedCategory($event.value)"
                            [autoDisplayFirst]="false"
                            [options]="categories"
                            #categoryIdInput
                            appendTo="body"
                            formControlName="categoryId"
                            placeholder="{{'journal.category' | translate}}"
                        >
                        </p-dropdown>

                        <app-form-validation
                            [control]="manageJournalEntryForm.get('categoryId')"
                            [input]="categoryIdInput"
                        >
                        </app-form-validation>
                    </div>
                </div>

                <div
                    class="m-form-group row"
                    [ngClass]="{ 'has-error': manageJournalEntryForm.get('timestamp').showErrors }"
                >
                    <label
                        class="col-4"
                        for="timestamp"
                    >
                        {{"journal.timestamp" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar
                            id="timestamp"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [hourFormat]="calendarSettings.hourFormat"
                            [monthNavigator]="false"
                            [showIcon]="true"
                            [showTime]="true"
                            [style]="{'width':'100%'}"
                            [yearNavigator]="false"
                            [yearRange]="primeComponentService.calendarYearRange"
                            #timestampInput
                            appendTo="body"
                            autoWidth="false"
                            formControlName="timestamp"
                        ></p-calendar>

                        <app-form-validation
                            [control]="manageJournalEntryForm.get('timestamp')"
                            [input]="timestampInput"
                        >
                        </app-form-validation>
                    </div>
                </div>

                <div
                    class="m-form-group row"
                    *ngIf="canSetSubCategory"
                >
                    <label
                        class="col-4"
                        for="subCategoryId"
                    >
                        {{"journal.subCategory" | translate}}
                    </label>

                    <div class="col-8">
                        <p-dropdown
                            id="subCategoryId"
                            [autoDisplayFirst]="false"
                            [options]="subCategories"
                            #subCategoryIdInput
                            appendTo="body"
                            formControlName="subCategoryId"
                            placeholder="{{'journal.subCategory' | translate}}"
                        >
                        </p-dropdown>

                        <app-form-validation
                            [control]="manageJournalEntryForm.get('subCategoryId')"
                            [input]="subCategoryIdInput"
                        >
                        </app-form-validation>
                    </div>
                </div>

                <div
                    class="m-form-group row"
                    [ngClass]="{ 'has-error': manageJournalEntryForm.get('projectId').showErrors }"
                    *ngIf="canSetProject"
                >
                    <label
                        class="col-4"
                        for="projectId"
                    >
                        {{"general.project" | translate}}
                    </label>

                    <div class="col-8">
                        <p-dropdown
                            id="projectId"
                            [autoDisplayFirst]="false"
                            [filter]="true"
                            [options]="projects"
                            [resetFilterOnHide]="true"
                            [showClear]="true"
                            [style]="{'width':'100%'}"
                            #projectIdInput
                            appendTo="body"
                            formControlName="projectId"
                            placeholder="{{'general.project' | translate}}"
                        >
                            <ng-template
                                let-project
                                pTemplate="item"
                            >
                                <span [ngClass]="project.styleClass">{{project.label}}</span>
                            </ng-template>
                        </p-dropdown>

                        <app-form-validation
                            [control]="manageJournalEntryForm.get('projectId')"
                            [input]="projectIdInput"
                        >
                        </app-form-validation>
                    </div>
                </div>

                <div class="m-form-group row">
                    <label
                        class="col-4"
                        for="remarks"
                    >
                        {{"journal.remarks" | translate}}
                    </label>

                    <div class="col-8">
                        <textarea
                            class="p-inputtextarea"
                            id="remarks"
                            #remarksInput
                            formControlName="remarks"
                            placeholder="{{'journal.remarks' | translate}}"
                            rows="5"
                        ></textarea>
                    </div>
                </div>

                <div
                    class="m-form-group row"
                    *ngIf="manageJournalEntryForm.get('adminRemarks')"
                >
                    <span
                        class="col-4"
                        for="adminRemarks"
                    >
                        {{"journal.adminRemarks" | translate}}
                    </span>

                    <div class="col-8">
                        <textarea
                            class="p-inputtextarea"
                            id="adminRemarks"
                            #adminRemarksInput
                            formControlName="adminRemarks"
                            placeholder="{{'journal.adminRemarks' | translate}}"
                            rows="5"
                        ></textarea>
                    </div>
                </div>

                <div
                    class="m-form-group row"
                    *ngIf="manageJournalEntryForm.get('isAdminOnly')"
                >
                    <span class="col-4"> {{"auditTrails.isAdminOnly" | translate}} </span>

                    <div class="col-8">
                        <p-inputSwitch formControlName="isAdminOnly"></p-inputSwitch>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <app-attachment-form
                    [context]="existingJournal"
                    [parentFormGroup]="manageJournalEntryForm"
                    [readonly]="readonly"
                ></app-attachment-form>
            </div>
        </div>
    </form>

    <p-footer>
        <ng-container>
            <button
                class="m-btn m-btn-link"
                id="close_add_log_entry_dialog_button"
                (click)="close()"
                [disabled]="submitting"
                *ngIf="!readonly"
                type="button"
            >
                {{"form.cancel" | translate}}
            </button>

            <button
                class="m-btn m-btn-primary"
                (click)="submit()"
                [disabled]="submitting"
                type="submit"
            >
                <app-loading-spinner *ngIf="submitting"> </app-loading-spinner>
                {{(readonly ? "form.close" : "form.save") | translate}}
            </button>
        </ng-container>
    </p-footer>
</p-dialog>
