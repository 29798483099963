import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AssignmentsService } from "../pages/assignments/services/assignments.service";
import { GlobalEventsService } from "./global-events.service";
import { MapDetailService } from "./map-detail.service";

import { MeasuringPointUtils, SubscriptionManager } from "@ramudden/core/utils";
import { IAssignment } from "@ramudden/models/assignment";
import { BackendRights } from "@ramudden/models/backend-rights";
import { IDevice } from "@ramudden/models/device";
import { IDeviceDisplayEvent } from "@ramudden/models/device-display-configuration";
import { IJournal } from "@ramudden/models/journal";
import { ICoordinate, ILocation } from "@ramudden/models/location";
import { IMeasuringPoint } from "@ramudden/models/measuring-point";
import { Rights } from "@ramudden/models/rights";
import { IScenario } from "@ramudden/models/scenario";
import { ISharedKey } from "@ramudden/models/shared-key";
import { IOrganization } from "@ramudden/models/user";
import { IMeasuringPointSummary } from "@ramudden/models/web";

@Injectable({
    providedIn: "root",
})
export class NavigationService implements OnDestroy {
    static isNavigating = false;

    deviceDisplayEventIdToDuplicate: number;
    newLocation: ILocation;

    clonedMeasuringPoint: IMeasuringPoint;

    // Used to navigate back to the correct screen
    cameFromMap: boolean;
    linkDeviceId: number;
    linkMeasuringPoint: IMeasuringPointSummary;

    private newOrganization: IOrganization;
    private subscriptionManager = new SubscriptionManager();
    private rights: Rights;

    constructor(
        private readonly translateService: TranslateService,
        private readonly globalEventsService: GlobalEventsService,
        private readonly mapDetailService: MapDetailService,
        private readonly router: Router,
        private readonly zone: NgZone,
        private readonly assignmentsService: AssignmentsService,
    ) {
        const rightsSubscription = this.globalEventsService.currentRights$.subscribe(
            (rights) => (this.rights = rights),
        );
        this.subscriptionManager.add("rightsSubscription", rightsSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptionManager.clear();
    }

    async toHome(): Promise<boolean> {
        if (this.mapDetailService.currentMapDetail) {
            return this.mapDetailService.navigateToMapDetail();
        }

        return this.navigate("/");
    }

    //#region Location

    async createNewMeasuringPointLocation(location: ILocation): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.EditMeasuringPoint)) return false;

        this.newLocation = location;
        return this.navigate("/locations/new");
    }

    async toDefaultMeasuringPointLocation(locationId: number): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.ViewMeasuringPoint)) return false;

        // This will default to the first location if it exists
        return this.navigate(`/locations/${locationId}/x`);
    }

    //#endregion Location

    //#region Project

    public getProjectUrl(projectId: number): string {
        return `/administration/project/${projectId}`;
    }

    async toProject(projectId: number, tab: string = null): Promise<boolean> {
        if (!projectId) return false;

        let url = this.getProjectUrl(projectId);

        if (tab) {
            url += `/${tab}`;
        }

        return this.navigate(url);
    }

    async createNewProject(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.EditProject)) return false;

        return this.navigate("/administration/project/new/details");
    }

    //#endregion Project

    //#region Measuring Point

    async toMeasuringPoints(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.ViewMeasuringPoint)) return false;

        return this.navigate("/locations");
    }

    toMeasuringPoint(measuringPoint: IMeasuringPointSummary, tab: string = null): Promise<boolean> {
        if (!measuringPoint) return Promise.resolve(false);

        return this.toMeasuringPointLocation(measuringPoint.id, measuringPoint.locationId, tab);
    }

    async toMeasuringPointLocation(
        id: number,
        locationId: number,
        tab: string = null,
        newTab = false,
    ): Promise<boolean> {
        if (!id || !locationId || !this.rights?.hasBackendRight(BackendRights.ViewMeasuringPoint)) return false;

        let url = `/locations/${locationId}/${id}`;

        if (tab) {
            url += `/${tab}`;
        }

        return this.navigate(url, null, newTab);
    }

    async createNewMeasuringPoint(locationId: number): Promise<boolean> {
        if (!location || !this.rights?.hasBackendRight(BackendRights.EditMeasuringPoint)) return false;

        return this.navigate(`/locations/${locationId}/new`);
    }

    // It seems that this method does not belong here at all...
    async createReverseMeasuringPoint(measuringPoint: IMeasuringPoint) {
        if (!measuringPoint || !this.rights?.hasBackendRight(BackendRights.EditMeasuringPoint)) return false;

        const pairs: { in: string; out: string }[] = [
            {
                in: this.translateService.instant("manageMeasuringPoint.directionIn"),
                out: this.translateService.instant("manageMeasuringPoint.directionOut"),
            },
            {
                in: "H",
                out: "T",
            },
        ];

        const reverseCode = (code: string) => {
            let pair: { in: string; out: string } = null;

            for (const loopPair of pairs) {
                if (
                    code.toLocaleLowerCase().endsWith(loopPair.in.toLocaleLowerCase()) ||
                    code.toLocaleLowerCase().endsWith(loopPair.out.toLocaleLowerCase())
                ) {
                    pair = loopPair;
                    break;
                }
            }

            if (!pair) return `${code} ${pairs.takeLastOrDefault().out}`;

            let postfix = pair.out;

            const inEndsWithText = " " + pair.in;
            const outEndsWithText = " " + pair.out;

            let isFullUpperCase: boolean;
            let isFullLowerCase: boolean;

            const setCaseFlags = (codeEnd: string) => {
                isFullUpperCase = codeEnd.toUpperCase() === codeEnd;
                isFullLowerCase = codeEnd.toLowerCase() === codeEnd;
            };

            if (code.toLowerCase().endsWith(inEndsWithText.toLowerCase())) {
                const codeEnd = code.substr(code.length - inEndsWithText.length);
                setCaseFlags(codeEnd);

                code = code.substr(0, code.length - inEndsWithText.length);
            } else if (code.toLowerCase().endsWith(outEndsWithText.toLowerCase())) {
                const codeEnd = code.substr(code.length - outEndsWithText.length);
                setCaseFlags(codeEnd);

                code = code.substr(0, code.length - outEndsWithText.length);
                postfix = pair.in;
            }

            if (isFullUpperCase) {
                postfix = postfix.toUpperCase();
            }

            if (isFullLowerCase) {
                postfix = postfix.toLowerCase();
            }

            code = `${code} ${postfix}`;

            return code.trim();
        };

        const reverseHeading = (heading: number) => {
            heading += 180;
            if (heading > 360) heading -= 360;
            return heading;
        };

        const reversedMeasuringPoint = {
            code: reverseCode(measuringPoint.code),
            description: measuringPoint.description,
            to: measuringPoint.from,
            from: measuringPoint.to,
            heading: reverseHeading(measuringPoint.heading),
            analysisTypeId: measuringPoint.analysisTypeId,
            drivingLane: measuringPoint.drivingLane,
            location: measuringPoint.location,
            projects: measuringPoint.projects,
        } as IMeasuringPoint;

        this.clonedMeasuringPoint = reversedMeasuringPoint;

        return this.navigate(`/locations/${measuringPoint.location.id}/new`);
    }

    // It seems that this method does not belong here at all...
    async createCopy(measuringPoint: IMeasuringPoint) {
        if (!measuringPoint || !this.rights?.hasBackendRight(BackendRights.EditMeasuringPoint)) return false;

        this.clonedMeasuringPoint = {
            code: measuringPoint.code,
            description: measuringPoint.description,
            to: measuringPoint.to,
            from: measuringPoint.from,
            heading: measuringPoint.heading,
            analysisTypeId: measuringPoint.analysisTypeId,
            drivingLane: measuringPoint.drivingLane,
            location: measuringPoint.location,
            projects: measuringPoint.projects,
        } as IMeasuringPoint;

        return this.navigate(`/locations/${measuringPoint.location.id}/new`);
    }

    //#endregion Measuring Point

    //#region Device

    async toDevices(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.ViewDevice)) return false;

        return this.navigate("/devices");
    }

    async toDevice(id: number, tab: string = null): Promise<boolean> {
        if (!id || !this.rights?.hasBackendRight(BackendRights.ViewDevice)) return false;

        let url = `/devices/${id}`;

        if (tab) {
            url += `/${tab}`;
        }

        return this.navigate(url);
    }

    async createNewDevice(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.EditDevice)) return false;

        return this.navigate("/devices/new");
    }

    //#endregion Device

    //#region Organization

    async toOrganizations(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.ViewOrganization)) return false;

        if (this.globalEventsService.hasMultipleOrganizations()) {
            return this.navigate("/administration");
        }

        await this.toOrganization(this.globalEventsService.getDefaultOrganization().id);
    }

    async toOrganization(id: number, tab: string = null): Promise<boolean> {
        if (!id || !this.rights?.hasBackendRight(BackendRights.ViewOrganization)) return false;

        let url = `/administration/organization/${id}/details`;

        if (!tab) {
            this.globalEventsService.getAuthorizationInfo();
            tab = this.rights.hasBackendRight(BackendRights.ViewOrganization)
                ? "details"
                : this.rights.hasBackendRight(BackendRights.EditUser)
                  ? "logo"
                  : "journal";
        }

        if (tab) {
            url += `/${tab}`;
        }
        return this.navigate(url);
    }

    async createNewOrganization(coordinates: ICoordinate): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.CreateOrganization)) return false;

        this.newOrganization = {
            id: null,
            location: {
                coordinate: coordinates,
            },
        } as IOrganization;

        return this.navigate("/administration/organization/new/details");
    }

    getNewOrganization(): IOrganization {
        const newOrganization = this.newOrganization;
        this.newOrganization = null;
        return newOrganization;
    }

    //#endregion Organization

    //#region Assignment

    toAssignments() {
        return this.navigate("/assignments");
    }

    toAssignment(assignment: IAssignment, tab: string = null, newTab = false): Promise<boolean> {
        if (!assignment) return Promise.resolve(false);

        return this.toAssignmentDetails(assignment.id, tab, newTab);
    }

    async toAssignmentDetails(id: number, tab: string = null, newTab = false): Promise<boolean> {
        if (!id || !this.rights?.hasBackendRight(BackendRights.ViewAssignment)) return false;

        let url = `/assignments/${id}`;
        if (tab) {
            url += `/${tab}`;
        }

        return this.navigate(url, null, newTab);
    }

    async createAssignmentAtLocation(coordinate: ICoordinate): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.CreateAssignment)) return false;
        this.assignmentsService.setAssignmentCoordinate(coordinate);
        return this.router.navigate(["/assignments", "new", "details"]);
    }

    //#endregion Assignment

    //#region Task

    toTask(assignmentId: number, taskId: number, tab: string = null): Promise<boolean> {
        if (!taskId) return Promise.resolve(false);

        tab = ["details", "attachments", "history"].contains(tab) ? tab : "details";
        return this.navigate(`/assignments/${assignmentId}/task/${taskId}/${tab}`);
    }

    async createNewTask(assignmentId: number): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.EditTask)) return false;

        return this.navigate(`/assignments/${assignmentId}/task/new`);
    }

    //#endregion Task

    //#region Parking ban

    toParkingBan(assignmentId: number, parkingBanId: number, tab: string = null): Promise<boolean> {
        if (!parkingBanId) return Promise.resolve(false);

        tab = ["details", "signscans", "parkingbanexceptions", "photos"].contains(tab) ? tab : "details";
        return this.navigate(`/assignments/${assignmentId}/parking-ban/${parkingBanId}/${tab}`);
    }

    async createNewParkingBan(assignmentId: number): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.EditParkingBan)) return false;

        return this.navigate(`/assignments/${assignmentId}/parking-ban/new`);
    }

    //#endregion Parking ban

    //#region Workers
    toWorker(workerId: number): Promise<boolean> {
        if (!workerId) return Promise.resolve(false);

        return this.navigate(`/workers/${workerId}/details`);
    }

    async createNewWorker(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.EditWorker)) return false;

        return this.navigate("/workers/new");
    }

    //#endregion Workers

    //#region Safety Question
    toSafetyQuestonDetail(safetyQuestionId: number): Promise<boolean> {
        if (!safetyQuestionId) return Promise.resolve(false);

        return this.navigate(`/questionsOverview/safetyQuestion/${safetyQuestionId}/details`);
    }

    async createNewSafetyQuestion(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.EditSafetyQuestion)) return false;

        return this.navigate("/questionsOverview/safetyQuestion/new");
    }

    toQuestionsOverview() {
        this.router.navigate(["/questionsOverview/safetyQuestion"]);
    }

    //#endregion Safety Question

    //#region Shared Key

    async toSharedKeys(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.ViewSharedKey)) return false;

        return this.navigate("data/shared/keys");
    }

    async createNewSharedKey(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.ViewSharedKey)) return false;

        return this.navigate("data/shared/keys/new");
    }

    async toSharedKey(sharedKey: ISharedKey) {
        if (!this.rights?.hasBackendRight(BackendRights.ViewSharedKey)) return false;

        return this.navigate(`data/shared/keys/${sharedKey.key}`);
    }

    //#endregion Shared Key

    //#region Device Display Events

    async toDeviceDisplayEvents(): Promise<boolean> {
        if (
            !this.rights?.hasBackendRight(BackendRights.ViewScenario) &&
            !this.rights?.hasBackendRight(BackendRights.ViewDeviceDisplayEvent)
        )
            return false;

        return this.navigate("/scenarios/device-display-events");
    }

    duplicateDeviceDisplayEvent(eventToDuplicate: IDeviceDisplayEvent) {
        if (
            !this.rights?.hasBackendRight(BackendRights.ViewScenario) &&
            !this.rights?.hasBackendRight(BackendRights.ViewDeviceDisplayEvent)
        )
            return false;

        this.deviceDisplayEventIdToDuplicate = eventToDuplicate.id;
        this.navigate("/scenarios/device-display-events/duplicate");
    }

    editDeviceDisplayEvent(event: IDeviceDisplayEvent) {
        if (
            !this.rights?.hasBackendRight(BackendRights.ViewScenario) &&
            !this.rights?.hasBackendRight(BackendRights.ViewDeviceDisplayEvent)
        )
            return false;
        const eventId = event ? event.id : "new";
        this.navigate(`/scenarios/device-display-events/${eventId}`);
    }

    //#endregion Device Display Events

    //#region Scenarios

    async toScenarios() {
        if (
            !this.rights?.hasBackendRight(BackendRights.ViewScenario) &&
            !this.rights?.hasBackendRight(BackendRights.ViewDeviceDisplayEvent)
        )
            return false;

        return this.navigate("/scenarios/scenarios");
    }

    async editScenario(scenario: IScenario = null) {
        if (
            !this.rights?.hasBackendRight(BackendRights.ViewScenario) &&
            !this.rights?.hasBackendRight(BackendRights.ViewDeviceDisplayEvent)
        )
            return false;
        const scenarioId = scenario?.id || "new";
        this.navigate(`/scenarios/scenarios/${scenarioId}`);
    }

    async viewScenario(scenario: IScenario) {
        if (
            !this.rights?.hasBackendRight(BackendRights.ViewScenario) &&
            !this.rights?.hasBackendRight(BackendRights.ViewDeviceDisplayEvent)
        )
            return false;
        this.navigate(`/scenarios/scenarios/${scenario.id}`, { readonly: 1 });
    }

    //#endregion

    //#region Device Links

    createLinkForMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.linkMeasuringPoint = MeasuringPointUtils.toSummary(measuringPoint);
        this.linkDeviceId = null;
        this.navigate(`/link/new/mp/${measuringPoint.id}`);
    }

    createLinkForDevice(deviceId: number) {
        this.linkMeasuringPoint = null;
        this.linkDeviceId = deviceId;
        this.navigate(`/link/new/devices/${deviceId}`);
    }

    replaceLinkDevice(deviceLinkId: number, measuringPoint: IMeasuringPoint = null, device: IDevice = null) {
        this.linkMeasuringPoint = MeasuringPointUtils.toSummary(measuringPoint);
        this.linkDeviceId = device ? device.id : null;
        this.navigate(`/link/replace/${deviceLinkId}`);
    }

    breakDeviceLink(deviceId: number, measuringPoint: IMeasuringPointSummary = null) {
        this.linkMeasuringPoint = measuringPoint;
        this.linkDeviceId = deviceId;
        this.navigate(`/link/break/${deviceId}`);
    }

    editLink(linkId: number, measuringPoint: IMeasuringPoint = null, device: IDevice = null) {
        this.linkMeasuringPoint = MeasuringPointUtils.toSummary(measuringPoint);
        this.linkDeviceId = device ? device.id : null;
        this.navigate(`/link/${linkId}`);
    }

    //#endregion Device Links

    //#region Validation

    async toValidation(): Promise<boolean> {
        if (!this.rights?.hasBackendRight(BackendRights.ViewVehicleDayOverviews)) return false;

        return this.navigate("/data/validation");
    }

    async toMeasuringPointValidation(measuringPoint: IMeasuringPointSummary) {
        if (!this.rights?.hasBackendRight(BackendRights.ViewVehicleDayOverviews)) return false;

        return this.navigate(`/data/validation/${measuringPoint.id}`);
    }

    //#endregion Validation

    //#region Journal

    getJournalUrl(journal: IJournal) {
        if (journal.measuringPoint) {
            return `${this.getHostName()}/locations/${journal.measuringPoint.locationId}/${journal.measuringPoint.id}/journal?id=${journal.id}`;
        }

        if (journal.device) {
            return `${this.getHostName()}/devices/${journal.device.id}/journal?id=${journal.id}`;
        }

        if (journal.organizationId) {
            return `${this.getHostName()}/administration/organization/${journal.organizationId}/journal&id=${journal.id}`;
        }

        if (journal.project) {
            return `${this.getHostName()}/administration/project/${journal.project.id}/journal?id=${journal.id}`;
        }

        return "";
    }

    //#endregion Journal

    //#region VMS Image editor
    editVmsImage(vmsImageId: number): Promise<boolean> {
        if (!vmsImageId) return Promise.resolve(false);
        return this.navigate(`/configuration/vms-images/${vmsImageId}`);
    }

    createVmsImage(): Promise<boolean> {
        return this.navigate("/configuration/vms-images/new");
    }

    //#endregion

    //#region Documentation

    toArticle(path: string): Promise<boolean> {
        if (!path) return Promise.resolve(false);
        if (path.startsWith("/") && path.length > 1) path = path.slice(1);

        return this.navigate(`/documentation/${path}`);
    }

    //#endregion

    //#region Reports

    getHistoryReportsUrl(): string {
        return `${this.getHostName()}/history/reports`;
    }

    //#endregion

    private getHostName(): string {
        let hostname = `${window.location.protocol}//${window.location.hostname}`;

        const port = window.location.port;
        if (port && port !== "80" && port !== "443") {
            hostname = `${hostname}:${port}`;
        }

        return hostname;
    }

    private navigate(url: string, queryParams: object = null, newTab = false): Promise<boolean> {
        if (newTab) {
            window.open(url);
            return Promise.resolve(false);
        }

        let promise: Promise<boolean>;

        this.zone.run(() => {
            promise = queryParams
                ? this.router.navigate([url], { queryParams: queryParams })
                : this.router.navigate([url]);
        });

        return promise;
    }
}
