<p-dialog
    (onHide)="onHide()"
    (onShow)="onShow()"
    [(visible)]="visible"
    [appendTo]="appendTo"
    [contentStyle]="contentStyle"
    [dismissableMask]="dismissOnClickOutside"
    [draggable]="draggable"
    [minX]="minX"
    [minY]="minY"
    [modal]="blockBackground"
>
    <p-header> {{"measuringPointDataDeleteDialog.title" | translate:measuringPoint}} </p-header>

    <form
        class="p-16"
        [formGroup]="dataDeleteForm"
        *ngIf="dataDeleteForm"
        autocomplete="off"
        novalidate
    >
        <div
            class="m-form-group row mb-16"
            formGroupName="rangeDeleteForm"
        >
            <div class="col-12 mb-16">
                <label (click)="setDateForm(rangeDeleteForm)">
                    <input
                        class="m-input-radio"
                        [checked]="rangeDeleteForm.enabled"
                        type="radio"
                    />
                    {{"measuringPointDataDeleteDialog.rangeTitle" | translate}}
                </label>
            </div>

            <div class="col-6">
                <div
                    class="row"
                    [ngClass]="{ 'has-error': rangeDeleteForm.get('from')?.showErrors }"
                >
                    <label
                        class="col-4"
                        for="from"
                    >
                        {{"general.from" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar
                            id="from"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [hourFormat]="calendarSettings.hourFormat"
                            [maxDate]="rangeDeleteForm.get('to')?.value"
                            [monthNavigator]="false"
                            [showIcon]="true"
                            [showTime]="true"
                            [yearNavigator]="false"
                            [yearRange]="primeComponentService.calendarYearRange"
                            #fromInput
                            autoWidth="false"
                            formControlName="from"
                        >
                            <ng-template
                                let-date
                                pTemplate="date"
                            >
                                <span [ngClass]="dataDaysService.getCssStyle(date)"> {{date.day}} </span>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <app-legend
                                    [configuration]="primeComponentService.calendarFooterConfiguration()"
                                ></app-legend>
                            </ng-template>
                        </p-calendar>

                        <app-form-validation
                            [control]="rangeDeleteForm.get('from')"
                            [input]="fromInput"
                        >
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div
                    class="row"
                    [ngClass]="{ 'has-error': rangeDeleteForm.get('to')?.showErrors }"
                >
                    <label
                        class="col-4"
                        for="to"
                    >
                        {{"general.until" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar
                            id="to"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [hourFormat]="calendarSettings.hourFormat"
                            [minDate]="rangeDeleteForm.get('from')?.value"
                            [monthNavigator]="false"
                            [showIcon]="true"
                            [showTime]="true"
                            [yearNavigator]="false"
                            [yearRange]="primeComponentService.calendarYearRange"
                            #toInput
                            autoWidth="false"
                            formControlName="to"
                        >
                            <ng-template
                                let-date
                                pTemplate="date"
                            >
                                <span [ngClass]="dataDaysService.getCssStyle(date)"> {{date.day}} </span>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <app-legend
                                    [configuration]="primeComponentService.calendarFooterConfiguration()"
                                ></app-legend>
                            </ng-template>
                        </p-calendar>

                        <app-form-validation
                            [control]="rangeDeleteForm.get('to')"
                            [input]="toInput"
                        >
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="m-form-group row"
            formGroupName="timeDeleteForm"
        >
            <div class="col-12 mb-16">
                <label (click)="setDateForm(timeDeleteForm)">
                    <input
                        class="m-input-radio"
                        [checked]="timeDeleteForm.enabled"
                        type="radio"
                    />
                    {{"measuringPointDataDeleteDialog.timeTitle" | translate}}
                </label>
            </div>

            <div class="col-3">
                <div
                    class="row"
                    [ngClass]="{ 'has-error': timeDeleteForm.get('from')?.showErrors }"
                >
                    <label
                        class="col-4"
                        for="from"
                    >
                        {{"general.from" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar
                            id="from"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [maxDate]="timeDeleteForm.get('to')?.value"
                            [monthNavigator]="false"
                            [showIcon]="true"
                            [yearNavigator]="false"
                            [yearRange]="primeComponentService.calendarYearRange"
                            #fromInput
                            autoWidth="false"
                            formControlName="from"
                        >
                            <ng-template
                                let-date
                                pTemplate="date"
                            >
                                <span [ngClass]="dataDaysService.getCssStyle(date)"> {{date.day}} </span>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <app-legend
                                    [configuration]="primeComponentService.calendarFooterConfiguration()"
                                ></app-legend>
                            </ng-template>
                        </p-calendar>

                        <app-form-validation
                            [control]="timeDeleteForm.get('from')"
                            [input]="fromInput"
                        >
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div
                    class="m-form-group row"
                    [ngClass]="{ 'has-error': timeDeleteForm.get('to')?.showErrors }"
                >
                    <label
                        class="col-4"
                        for="to"
                    >
                        {{"general.until" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar
                            id="to"
                            [dateFormat]="calendarSettings.dateFormat"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [minDate]="timeDeleteForm.get('from')?.value"
                            [monthNavigator]="false"
                            [showIcon]="true"
                            [yearNavigator]="false"
                            [yearRange]="primeComponentService.calendarYearRange"
                            #toInput
                            autoWidth="false"
                            formControlName="to"
                        >
                            <ng-template
                                let-date
                                pTemplate="date"
                            >
                                <span [ngClass]="dataDaysService.getCssStyle(date)"> {{date.day}} </span>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <app-legend
                                    [configuration]="primeComponentService.calendarFooterConfiguration()"
                                ></app-legend>
                            </ng-template>
                        </p-calendar>

                        <app-form-validation
                            [control]="timeDeleteForm.get('to')"
                            [input]="toInput"
                        >
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div
                    class="row"
                    [ngClass]="{ 'has-error': timeDeleteForm.get('start')?.showErrors }"
                >
                    <label
                        class="col-4"
                        for="start"
                    >
                        {{"general.from" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar
                            id="start"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [hourFormat]="calendarSettings.hourFormat"
                            [showIcon]="true"
                            [timeOnly]="true"
                            #startInput
                            autoWidth="false"
                            formControlName="start"
                        >
                        </p-calendar>

                        <app-form-validation
                            [control]="timeDeleteForm.get('start')"
                            [input]="startInput"
                        >
                        </app-form-validation>
                    </div>
                </div>
            </div>

            <div class="col-3">
                <div
                    class="row"
                    [ngClass]="{ 'has-error': timeDeleteForm.get('end')?.showErrors }"
                >
                    <label
                        class="col-4"
                        for="end"
                    >
                        {{"general.until" | translate}}
                    </label>

                    <div class="col-8">
                        <p-calendar
                            id="end"
                            [firstDayOfWeek]="calendarSettings.firstDayOfWeek"
                            [hourFormat]="calendarSettings.hourFormat"
                            [showIcon]="true"
                            [timeOnly]="true"
                            #endInput
                            autoWidth="false"
                            formControlName="end"
                        >
                        </p-calendar>

                        <app-form-validation
                            [control]="timeDeleteForm.get('end')"
                            [input]="endInput"
                        >
                        </app-form-validation>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <p-footer>
        <button
            class="m-btn m-btn-secondary"
            (click)="close()"
            [disabled]="submitting"
            type="button"
        >
            {{"form.cancel" | translate}}
        </button>

        <button
            class="m-btn m-btn-primary"
            (click)="submit()"
            [disabled]="submitting"
            type="submit"
        >
            <app-loading-spinner *ngIf="submitting"> </app-loading-spinner>
            {{"form.delete" | translate}}
        </button>
    </p-footer>
</p-dialog>
