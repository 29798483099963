<div
    id="{{ column.table.key + column.field + 'Filter' }}"
    *ngIf="column && column.filterType > 0"
    [ngClass]="{ 'is-outside-table': isOutsideTable }"
    (click)="stop($event)"
>
    <app-svg-icon
        id="{{ column.table.key + column.field + 'FilterIcon' }}"
        *ngIf="column.filterType !== 6 && !isOutsideTable"
        (click)="toggleExpanded($event)"
        icon="filter"
    >
    </app-svg-icon>

    <div
        class="m-column-search"
        *ngIf="column.filterType === 1"
    >
        <input
            class="p-inputtext"
            id="{{ column.table.key + column.field + 'FilterInput' }}"
            #textInput
            [(ngModel)]="currentFilter"
            [debounceTime]="400"
            (appDebounce)="filter($event, 'contains')"
            (blur)="expanded = false"
            (focus)="expanded = true"
            (keydown)="validateKeypress($event)"
            (keyup)="$event.stop()"
            placeholder="{{ column.header | translate }}"
            type="text"
        />
    </div>

    <div
        class="m-column-slider"
        *ngIf="column.filterType === 2 && expanded"
        [ngStyle]="expanded ? { display: 'block' } : {}"
    >
        <div class="m-slider-label">
            <input
                class="hide-spinner"
                id="{{ column.table.key + column.field + 'FilterSliderInput' }}"
                [debounceTime]="400"
                [ngModel]="currentFilter"
                (appDebounce)="filterNumber($event)"
                (click)="stop($event)"
                placeholder="{{ column.header | translate }}"
                type="number"
            />

            <app-svg-icon
                id="{{ column.field + 'ClearFilter' }}"
                *ngIf="currentFilter"
                [ngStyle]="{ cursor: 'pointer' }"
                (click)="clearFilter()"
                icon="close"
            >
            </app-svg-icon>
        </div>

        <p-slider
            id="{{ column.table.key + column.field + 'FilterSlider' }}"
            [(ngModel)]="currentFilter"
            [debounceTime]="400"
            [max]="column.filterMax || 100000"
            [min]="column.filterMin || 0"
            [step]="column.step || 1"
            (appDebounce)="filterNumber($event)"
        >
        </p-slider>
    </div>

    <p-calendar
        class="m-column-calendar"
        id="{{ column.table.key + column.field + 'FilterCalendar' }}"
        *ngIf="column.filterType === 3"
        [(ngModel)]="currentFilter"
        [dateFormat]="column.table.calendarSettings.dateFormat"
        [hourFormat]="column.table.calendarSettings.hourFormat"
        [monthNavigator]="false"
        [selectionMode]="column.filterMatchMode === 'inside' ? 'range' : 'single'"
        [showIcon]="true"
        [yearNavigator]="false"
        [yearRange]="primeComponentService.calendarYearRange"
        (click)="stop($event)"
        (onClearClick)="clearFilter()"
        (onSelect)="filterDate(currentFilter)"
        appendTo="body"
        autoWidth="false"
        placeholder="{{ column.header | translate }}"
        readonlyInput="true"
        showButtonBar="true"
    >
    </p-calendar>

    <p-dropdown
        class="m-column-dropdown"
        id="{{ column.table.key + column.field + 'FilterDropdown' }}"
        *ngIf="column.filterType === 4 && column.filterOptions"
        [(ngModel)]="currentFilter"
        [autoDisplayFirst]="false"
        [filter]="column.displayDropdownFilter"
        [options]="column.filterOptions"
        [showClear]="isOutsideTable"
        [style]="{ width: '100%' }"
        (click)="toggleExpanded(); stop($event)"
        (onChange)="filter($event.value, 'equals')"
        (onHide)="close()"
        appendTo="body"
        placeholder="{{ column.header | translate }}"
    >
    </p-dropdown>

    <p-multiSelect
        class="m-column-multiselect"
        id="{{ column.table.key + column.field + 'FilterMultiSelect' }}"
        *ngIf="column.filterType === 5 && column.filterOptions"
        [(ngModel)]="currentFilter"
        [filter]="column.displayDropdownFilter"
        [options]="column.filterOptions"
        [showHeader]="column.displayDropdownFilter"
        [showToggleAll]="false"
        (click)="closeIfTargetIsNotListItem($event)"
        (onChange)="filter($event.value, 'in')"
        (onPanelHide)="close($event)"
        (onPanelShow)="open($event)"
        appendTo="body"
        placeholder="{{ column.header | translate }}"
    >
    </p-multiSelect>

    <p-triStateCheckbox
        id="{{ column.table.key + column.field + 'FilterCheckbox' }}"
        *ngIf="column.filterType === 6 && column.type === 5"
        [(ngModel)]="currentFilter"
        [label]="isOutsideTable ? (column.header | translate) : ''"
        (click)="stop($event)"
        (onChange)="filterBoolean($event.value)"
    ></p-triStateCheckbox>

    <p-checkbox
        id="{{ column.table.key + column.field + 'FilterCheckbox' }}"
        *ngIf="column.filterType === 6 && column.type === 6"
        [(ngModel)]="currentFilter"
        [binary]="true"
        [label]="isOutsideTable ? (column.header | translate) : ''"
        (click)="stop($event)"
        (onChange)="filterBoolean($event.checked)"
        name="{{ column.table.key + column.field + 'FilterCheckbox' }}"
    ></p-checkbox>
</div>
