import { Injectable } from "@angular/core";
import { ApplicationCreator, ApplicationUpdater, IApplication } from "@ramudden/models/application";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class ApplicationApi extends ApiService<IApplication, ApplicationCreator, ApplicationUpdater> {
    override getRoute(): string {
        return "Applications";
    }
}
