<div
    [style.height]="responsive && !height ? null : height"
    [style.width]="responsive && !width ? null : width"
    style="text-align: center"
>
    <canvas
        class="m-chart"
        #canvas
        [attr.height]="responsive && !height ? null : height"
        [attr.width]="responsive && !width ? null : width"
        (click)="handleCanvasClick($event)"
    >
    </canvas>

    <app-loading-spinner *ngIf="showLoadingSpinner && !configuration"> </app-loading-spinner>
</div>
