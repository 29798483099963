import { Component, ElementRef, Input } from "@angular/core";
import { IDeviceSummary } from "@ramudden/models/device";
import { IDevicesFilter } from "@ramudden/models/report-type";
import { DeviceWebApi } from "@ramudden/data-access/resource/web";
import { forkJoin } from "rxjs";
import { MapSelectionService } from "../../../services/map-selection.service";
import { CustomTableComponent, FilterType, TableColumn, TableService } from "../table/table.component";

@Component({
    selector: "app-select-devices",
    templateUrl: "./select-devices.component.html",
})
export class SelectDevicesComponent extends CustomTableComponent<IDeviceSummary> {
    @Input() deleteCommand = true;
    @Input() ignoreServiceEvents = false;

    constructor(
        elementRef: ElementRef,
        tableService: TableService,
        private readonly selectionService: MapSelectionService,
        private readonly deviceWebApi: DeviceWebApi,
    ) {
        super("select-devices.component", elementRef, tableService);

        this.selectionMode = null;
        this.paginator = false;
        this.footer = false;
        this.filter = false;
        this.sortable = false;

        this.addColumn(new TableColumn("code", "devices.code", { filterType: FilterType.Text, sortable: true }));

        const addData = (dataToAdd: IDeviceSummary[]) => {
            if (this.ignoreServiceEvents) return;

            this.addData(dataToAdd);
        };

        const removeData = (dataToRemove: IDeviceSummary[]) => {
            if (this.ignoreServiceEvents) return;

            this.removeData(dataToRemove);
        };

        this.selectionService.subscribeToDevices(
            this.subscriptionManager,
            (x) => addData(x),
            (x) => removeData(x),
        );

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (device) => this.removeData([device]),
            validFunc: () => this.deleteCommand,
        });
    }

    onSetData() {
        if (!this.ignoreServiceEvents) {
            this.updateSelectionService();
        }
    }

    updateSelectionService() {
        this.selectionService.setDevices(this.data);
    }

    //#region Reporting
    async setFromFilter(deviceFilters: IDevicesFilter) {
        if (!deviceFilters) return;

        const devices = (await forkJoin(
            deviceFilters.ids.map((x) => this.deviceWebApi.get(x)),
        ).toPromise()) as IDeviceSummary[];

        this.setData(devices);
    }

    //#endregion Reporting
}
