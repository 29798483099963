import { Injectable } from "@angular/core";
import { IJournalCategory, JournalCategorySaver } from "@ramudden/models/journal";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class JournalCategoryApi extends ApiService<IJournalCategory, JournalCategorySaver, JournalCategorySaver> {
    override getRoute(): string {
        return "JournalCategories";
    }

    restore$(id: number): Observable<void> {
        const url = `${super.getUrl()}/${id}/Restore`;
        return this.http.patch<void>(url, null);
    }
}
