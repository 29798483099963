import { Injectable, NgZone, inject } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { JsonUtils, SubscriptionManager } from "@ramudden/core/utils";
import { AlertLevel } from "@ramudden/models/alert";
import { MeasuringPointLinkStatus } from "@ramudden/models/map-filter";
import { AnalysisType } from "@ramudden/models/measuring-point";
import { MeasuringPointSearchParameters } from "@ramudden/models/web";
import { OrganizationApi } from "@ramudden/data-access/resource/organization.api";
import { UserApi } from "@ramudden/data-access/resource/user.api";
import { ProjectWebApi } from "@ramudden/data-access/resource/web";
import { Subject, firstValueFrom } from "rxjs";
import { FilterState } from "./filter-state";

export enum FilterServiceFields {
    alertLevels = "alertLevels",
    analysisTypes = "types",
    linkStatus = "linkStatus",
    organizations = "organizations",
    projects = "projects",
    projectsForUserId = "projectsForUserId",
    search = "search",
}

/**
 * Shared access to the current filter parameters.
 * For now this service is only used in this module, so it is not injected in root.
 *
 * Responsibilities:
 * * Share access to the current filter parameters
 * * Notify when the filter parameters change
 * * Remember the filter state between navigations
 * * Parse filters from QueryParameters and populate the UI
 */
@Injectable({
    providedIn: "root",
})
export class FilterService {
    private previousFilterState = new FilterState(); // Keeps track of the previous value
    public filterState = new FilterState(); // Keeps track of the current value
    private readonly userApi = inject(UserApi);
    private readonly organizationApi = inject(OrganizationApi);
    private readonly projectApi = inject(ProjectWebApi);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly subscriptionManager = new SubscriptionManager();
    private readonly ngZone = inject(NgZone);
    private ignoreNextQueryParamChange = false;
    public nextLoadShouldZoomToNewFilter = false;
    public parsingFromQueryParams = false;

    private filterStateChangedSubject = new Subject<FilterState>(); // Not using BehaviorSubject because that is already triggered when subscribing
    public onFilterStateChanged$ = this.filterStateChangedSubject.asObservable(); // It is a best practice not to expose the subject directly

    constructor() {
        const querySub = this.activatedRoute.queryParams.subscribe((params) => {
            // this should only trigger when the url is set from non-manual filtering
            if (params.hasOwnProperty("reset") && params["reset"] === "true") {
                this.filterState.clear();
                this.ignoreNextQueryParamChange = false;
            }
            if (params.hasOwnProperty("panAndZoom") && params["panAndZoom"] === "true") {
                this.nextLoadShouldZoomToNewFilter = true;
            }
            if (this.ignoreNextQueryParamChange) {
                this.ignoreNextQueryParamChange = false;
                return;
            }

            this.fromQueryParams(params);
        });
        this.subscriptionManager.add("queryParams", querySub);
    }

    public notifyFilterStateChanged(isManualEditByUser = true) {
        // On notify of filter update, also set the url to current filter state
        const queryParams = this.getFrontEndUrlQueryParams();

        if (isManualEditByUser) {
            this.ignoreNextQueryParamChange = true;
        }

        this.updateShouldZoomToNewFilter();
        this.savePreviousFilterState();

        this.ngZone.run(() => {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                // queryParamsHandling: "merge", // remove to replace all query params by provided
            });
        });

        this.filterStateChangedSubject.next(this.filterState);
    }

    private async fromQueryParams(params: Params) {
        this.parsingFromQueryParams = true;

        // When navigating using the browser back or forward
        // it's possible queryparams get loaded on-top of existing ones
        // So sanity check before adding to filterState
        const getValues = (valueInParams: any) => {
            return (valueInParams + "").split(",");
        };

        this.savePreviousFilterState();

        const organizationsParam = params[FilterServiceFields.organizations];
        if (organizationsParam) {
            const organizationIds = getValues(organizationsParam).map((x) => +x);

            for (const organizationId of organizationIds) {
                if (this.filterState.organizations.find((x) => x.id === organizationId)) continue;

                const organization = await firstValueFrom(this.organizationApi.get$(organizationId));
                this.filterState.organizations.push(organization);
            }
        }

        const alertLevelsParam = params[FilterServiceFields.alertLevels];
        if (alertLevelsParam) {
            const alertLevels = getValues(alertLevelsParam).map((x) => <AlertLevel>x);

            for (const alertLevel of alertLevels) {
                if (this.filterState.alertLevels.contains(alertLevel)) continue;

                this.filterState.alertLevels.push(alertLevel);
            }
        }

        const projectsParams = params[FilterServiceFields.projects];
        if (projectsParams) {
            const projectIds = getValues(projectsParams).map((x) => +x);

            for (const projectId of projectIds) {
                if (this.filterState.projects.find((x) => x.id === projectId)) continue;

                const project = await this.projectApi.get(projectId);
                this.filterState.projects.push(project);
            }
        }

        const analysisTypeParams = params[FilterServiceFields.analysisTypes];
        if (analysisTypeParams) {
            const analysisTypes = getValues(analysisTypeParams).map((x) => <AnalysisType>x);

            for (const analysisType of analysisTypes) {
                if (this.filterState.types.contains(analysisType)) continue;

                this.filterState.types.push(analysisType);
            }
        }

        const hasLinkedDeviceParam = params[FilterServiceFields.linkStatus];
        if (hasLinkedDeviceParam) {
            this.filterState.linkStatus =
                hasLinkedDeviceParam === "true" ? MeasuringPointLinkStatus.Linked : MeasuringPointLinkStatus.NotLinked;
        }

        const searchParam = params[FilterServiceFields.search];
        if (searchParam) {
            this.filterState.searchTerm = searchParam;
        }

        const projectsForUserId = params[FilterServiceFields.projectsForUserId];
        if (projectsForUserId === "me") {
            this.filterState.projectsForUserId = await firstValueFrom(this.userApi.getSelf$());
        }

        this.parsingFromQueryParams = false;

        // As long as the queryParams we navigated to have the data required to zoom,
        // we want to zoom.
        // It's possible you're filtering on project X and get an alert for project X
        // If we'd content comparison, we'd think they're not really doing any new filtering at all
        // But since we came from queryParams, we know the user did a specific navigation
        // and zooming is advisable
        this.updateShouldZoomToNewFilter(false);

        this.notifyFilterStateChanged(false);
    }

    private savePreviousFilterState() {
        this.previousFilterState = JsonUtils.deepClone(this.filterState);
    }

    private updateShouldZoomToNewFilter(requiresChangedData = true) {
        if (this.nextLoadShouldZoomToNewFilter) return;

        // SCS-418 Zoom to new filter if we changed projects / organizations
        this.nextLoadShouldZoomToNewFilter =
            (!!this.filterState.organizations.length &&
                (!requiresChangedData ||
                    !this.filterState.organizations.areContentsEqual(
                        this.previousFilterState?.organizations,
                        (x) => x.id,
                    ))) ||
            (!!this.filterState.projects.length &&
                (!requiresChangedData ||
                    !this.filterState.projects.areContentsEqual(this.previousFilterState?.projects, (x) => x.id)));
    }

    private getFrontEndUrlQueryParams(): Params {
        // This is *not* the same as the API url
        // That one is rather funky & unreadable & long
        // So we're not using MeasuringPointSearchParameters.getHttpParams();
        const parameters = this.getSearchParameters();

        const params = {};

        if (parameters.alertLevelIds) {
            params[FilterServiceFields.alertLevels] = parameters.alertLevelIds.join(",");
        }

        if (parameters.ownerIds) {
            params[FilterServiceFields.organizations] = parameters.ownerIds.join(",");
        }

        if (parameters.projectIds) {
            params[FilterServiceFields.projects] = parameters.projectIds.join(",");
        }

        if (parameters.analysisTypeIds) {
            params[FilterServiceFields.analysisTypes] = parameters.analysisTypeIds.join(",");
        }

        // if "filled in" so either true or false, no binary checks
        if (parameters.hasLinkedDevice === true || parameters.hasLinkedDevice === false) {
            params[FilterServiceFields.linkStatus] = parameters.hasLinkedDevice === true;
        }

        if (parameters.search) {
            params[FilterServiceFields.search] = parameters.search;
        }

        if (parameters.projectsForUserId) {
            params[FilterServiceFields.projectsForUserId] = "me";
        }

        return params;
    }

    // Convert our FE filter state in BE search parameters
    public getSearchParameters(): MeasuringPointSearchParameters {
        const result = new MeasuringPointSearchParameters();
        if (this.filterState.projects.length) {
            result.projectIds = this.filterState.projects.map((x) => x.id);
        }

        if (this.filterState.types.length) {
            result.analysisTypeIds = this.filterState.types;
        }
        if (this.filterState.alertLevels.length) {
            result.alertLevelIds = this.filterState.alertLevels;
        }

        if (this.filterState.linkStatus === MeasuringPointLinkStatus.Linked) {
            result.hasLinkedDevice = true;
        } else if (this.filterState.linkStatus === MeasuringPointLinkStatus.NotLinked) {
            result.hasLinkedDevice = false; // Note that false has a different meaning than null here
        }

        if (this.filterState.organizations.length) {
            result.ownerIds = this.filterState.organizations.map((x) => x.id);
        }

        if (this.filterState.searchTerm) {
            result.search = this.filterState.searchTerm;
        }

        if (this.filterState.projectsForUserId) {
            result.projectsForUserId = this.filterState.projectsForUserId.id;
        }

        return result;
    }

    setQueryParametersInUrl() {
        this.notifyFilterStateChanged(false);
    }

    hideQueryParametersFromUrl() {
        this.ngZone.run(() => {
            this.ignoreNextQueryParamChange = true;
            this.router.navigate([], {
                queryParams: {},
            });
        });
    }
}
