import { AbstractControl } from "@angular/forms";
import { FormUtils } from "@ramudden/core/utils";
import { SigncoFormControl } from "@ramudden/models/form";
import moment, { Duration, isDuration } from "moment";

export class MinMaxValidatorOptions {
    // tslint:disable: whitespace
    includeDisabled? = false;
    isDateTime? = false;
    includeSeconds? = false;
    includeDay? = false;
    isDuration? = false;
    // tslint:enable: whitespace
}

export class MinMaxValidator {
    static runningValidity = false;

    static create(
        minControl: SigncoFormControl,
        maxControl: SigncoFormControl,
        options = new MinMaxValidatorOptions(),
    ) {
        options = Object.assign(new MinMaxValidatorOptions(), options);

        const validatorFunc = (curControl: AbstractControl) => {
            if (!this.runningValidity) {
                this.runningValidity = true;

                const otherControl = curControl === minControl ? maxControl : minControl;

                otherControl.updateValueAndValidity({ emitEvent: true, onlySelf: true });
                otherControl.markAsTouched();

                this.runningValidity = false;
            }

            if ((minControl.disabled || maxControl.disabled) && !options.includeDisabled) return null;

            if (maxControl.value === null || maxControl.value === undefined) return null;
            if (minControl.value === null || minControl.value === undefined) return null;

            const getNumberValue = (value: number | Date | Duration): number => {
                if (value instanceof Date) {
                    value = value.getTime();
                }

                if (isDuration(value)) {
                    value = value.asMilliseconds();
                }

                if (!Number.isInteger(value)) {
                    console.error("MinMaxValidator: value is not an integer", value);
                    return null;
                }

                return value;
            };

            const getStringValue = (value: number | Date | Duration): string => {
                if (value instanceof Date) {
                    if (options.isDuration) {
                        return moment(value).format(options.includeSeconds ? "LTS" : "LT");
                    }

                    if (options.isDateTime) {
                        const formatString =
                            (options.includeDay ? "ddd " : "") + "L LT" + (options.includeSeconds ? "S" : "");
                        return moment(value).format(formatString);
                    }

                    return moment(value).format(options.includeDay ? "ddd L" : "L");
                }

                if (isDuration(value)) {
                    return `${value.hours}:${value.minutes}`;
                }

                return value.toString();
            };

            const minValue = minControl.value;
            const minValueNumber = getNumberValue(minValue);

            const maxValue = maxControl.value;
            const maxValueNumber = getNumberValue(maxValue);

            const isValid = minValueNumber <= maxValueNumber;

            const nonValidReturnValue =
                curControl === minControl
                    ? { max: { max: getStringValue(maxValue) } }
                    : { min: { min: getStringValue(minValue) } };

            return !isValid ? nonValidReturnValue : null;
        };

        FormUtils.addValidator(minControl, validatorFunc);
        FormUtils.addValidator(maxControl, validatorFunc);
    }
}
