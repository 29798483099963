import { Injectable } from "@angular/core";
import { SearchParameters, ServiceRequestOptions } from "@ramudden/models/search";
import { ApiServiceBase, CacheOptions } from "../api";

@Injectable()
export class NavigatorWebApiService<T> extends ApiServiceBase {
    protected override getApiVersion(): string {
        return "web";
    }

    get$(
        id: number = null,
        searchParameters: SearchParameters = null,
        serviceRequestOptions: ServiceRequestOptions = null,
        useCache: boolean | CacheOptions = true,
    ): Promise<T> {
        let urlAppend: string = null;
        if (id) {
            urlAppend = `/${id}`;
        }

        return this.getOther$<T>(urlAppend, searchParameters, serviceRequestOptions, useCache).toPromise();
    }
}
