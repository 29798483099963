import { Pipe, PipeTransform } from "@angular/core";
import { IAssignment } from "@ramudden/models/assignment";
import { IAssignmentPlanningSummary } from "@ramudden/models/web";

@Pipe({
    name: "assignmentName",
})
export class AssignmentNamePipe implements PipeTransform {
    transform(assignment: IAssignment | IAssignmentPlanningSummary): string {
        if (!assignment) {
            return null;
        }

        if (!assignment.parentAssignment) {
            return assignment.name;
        }

        return `${assignment.parentAssignment.name} - ${assignment.name}`;
    }
}
