import { Injectable } from "@angular/core";
import {
    BatteryDischargeTableCreator,
    BatteryDischargeTableUpdater,
    IBatteryDischargeTable,
} from "@ramudden/models/battery-discharge-table";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class BatteryDischargeTableApi extends ApiService<
    IBatteryDischargeTable,
    BatteryDischargeTableCreator,
    BatteryDischargeTableUpdater
> {
    override getRoute(): string {
        return "BatteryDischargeTables";
    }
}
