import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TaskApi } from "@ramudden/data-access/resource/task.api";
import { PageBodyContentComponent } from "apps/portal/src/app/layout/page/page-body/page-body-content/page-body-content.component";
import { AssignmentsService } from "apps/portal/src/app/pages/assignments/services/assignments.service";
import { SharedModule } from "apps/portal/src/app/shared/shared.module";
import { firstValueFrom } from "rxjs";

const TAB_MAPPING = {
    details: "details",
    attachments: "attachments",
    history: "history",
};

@Component({
    selector: "app-task-redirect",
    standalone: true,
    imports: [PageBodyContentComponent, SharedModule],
    templateUrl: "./task-redirect.component.html",
    styleUrl: "./task-redirect.component.scss",
})
export class TaskRedirectComponent implements OnInit {
    private readonly assignmentsService = inject(AssignmentsService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly taskApi = inject(TaskApi);

    async ngOnInit() {
        const tab = TAB_MAPPING[this.route.snapshot.params["tab"]] || "details";
        const taskId = this.route.snapshot.params["taskId"];
        if (taskId) {
            const task = await firstValueFrom(
                this.taskApi.get$(taskId, null, this.assignmentsService.getTaskServiceRequestOptions()),
            );
            this.router.navigate(["/assignments", task.assignment.id, "task", task.id, tab]);
        } else {
            this.router.navigate(["/assignments"]);
        }
    }
}
