import { DeviceType } from "@ramudden/models/device";

export class DeviceUtils {
    static getTubeRatioCellClass(tube1Ratio: number): string {
        if (tube1Ratio > 0.6 || tube1Ratio < 0.4) return "m-pulse-error";
        if (tube1Ratio > 0.55 || tube1Ratio < 0.45) return "m-pulse-warning";
        return "";
    }

    static getSkippedRatioCellClass(skippedPulseRatio: number): string {
        if (skippedPulseRatio > 0.05) return "m-pulse-error";
        return "";
    }

    static getPulseConversionRateCellClass(pulseConversionRate: number): string {
        if (pulseConversionRate < 0.5) return "m-pulse-warning";
        return "";
    }

    static supportsScenarios(deviceTypeId: DeviceType): boolean {
        return (
            deviceTypeId === DeviceType.BikeCounter ||
            deviceTypeId === DeviceType.Relay ||
            deviceTypeId === DeviceType.GenericLinuxController
        );
    }

    static supportsAdvancedHardwareConfiguration(packageId: string): boolean | string {
        return packageId && packageId.startsWith("dekimo");
    }

    static supportsDeepSleep(type: DeviceType): boolean {
        return type && type === DeviceType.DekimoController;
    }
}
