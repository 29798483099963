import { Component, ElementRef, inject, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataGroup, DataItem, Timeline } from "vis-timeline";
@Component({
    selector: "app-timeline-chart",
    templateUrl: "./timeline-chart.component.html",
    styleUrls: ["./timeline-chart.component.scss"],
})
export class TimelineChartComponent implements OnInit {
    @ViewChild("timelineChart", { static: true }) timelineChart: ElementRef<HTMLDivElement>;
    private readonly translate = inject(TranslateService);

    timeline: Timeline;

    private _config: TimelineChartConfig;

    isLoading = false;
    @Input() set config(config: TimelineChartConfig) {
        this.setConfig(config);
    }
    get config() {
        return this._config;
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(() => {
            if (this.timeline) {
                this.timeline.setOptions({
                    locale: this.translate.currentLang,
                });
            }
        });
    }

    setConfig(config: TimelineChartConfig) {
        this.isLoading = true;
        this._config = config;
        if (config) {
            if (this.timeline) {
                this.timeline.destroy();
            }
            this.timeline = new Timeline(this.timelineChart.nativeElement, config.items, config.groups, {
                stack: false,
                stackSubgroups: false,
                min: config.min,
                max: config.max,
                end: config.max,
                locale: this.translate.currentLang,
                onInitialDrawComplete: () => {
                    this.isLoading = false;
                },
            });
        }
    }
}

export class TimelineChartConfig {
    groups: DataGroup[];
    items: DataItem[];
    min: Date;
    max: Date;
}
