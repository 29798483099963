import { Component, inject, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { IAssignment } from "@ramudden/models/assignment";
import { BackendRights } from "@ramudden/models/backend-rights";
import { IDevice } from "@ramudden/models/device";
import { SigncoFormGroup } from "@ramudden/models/form";
import { IJournal, JournalCreator, JournalUpdater } from "@ramudden/models/journal";
import { IMeasuringPoint } from "@ramudden/models/measuring-point";
import { IProject } from "@ramudden/models/project";
import { IOrganization } from "@ramudden/models/user";
import { JournalApi } from "@ramudden/data-access/resource/journal.api";
import { FormValidationService } from "@ramudden/services";
import { SelectItem } from "primeng/api";
import { FilterService } from "../../../pages/installations/services/filter.service";
import { DomainModelFilterService } from "../../../services/domain-model-filter.service";
import { GlobalEventsService } from "../../../services/global-events.service";
import { CalendarSettings, PrimeComponentService } from "../../../services/prime-component.service";
import { AttachmentFormComponent } from "../attachment-form/attachment-form.component";
import { DialogComponentBase } from "../dialog/dialog.component";

type JournalRelatedTypes = IDevice | IMeasuringPoint | IOrganization | IProject | IAssignment;

@Component({
    selector: "app-journal-dialog",
    templateUrl: "./journal.dialog.html",
})
export class JournalDialogComponent extends DialogComponentBase {
    @ViewChild(AttachmentFormComponent, { static: false }) attachmentFormComponent: AttachmentFormComponent;

    submitting: boolean;
    closeCallback: () => void;
    saveCallback: (res: IJournal) => void;
    manageJournalEntryForm: SigncoFormGroup;
    calendarSettings: CalendarSettings;
    existingJournal: IJournal;
    categories: SelectItem[];
    subCategories: SelectItem[];
    projects: SelectItem[];
    readonly = false;
    canSetProject = false;
    canSetSubCategory = false;
    private isMaas = false;
    private droppedFiles: FileList;

    relatedObject: JournalRelatedTypes;
    translationObject: { code: string };

    private readonly filterService = inject(FilterService);

    constructor(
        readonly formValidationService: FormValidationService,
        private readonly globalEventsService: GlobalEventsService,
        readonly primeComponentService: PrimeComponentService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly journalApi: JournalApi,
        private readonly domainModelFilterService: DomainModelFilterService,
    ) {
        super();

        const calendarSettingsSubscription = this.primeComponentService
            .calendarSettings()
            .subscribe((calendarSettings) => {
                this.calendarSettings = calendarSettings;
            });

        this.subscriptionManager.add("calendarSettings", calendarSettingsSubscription);
    }

    create(
        relatedObject: JournalRelatedTypes,
        callback?: (result: IJournal) => void,
        canSetProject = false,
        canSetSubCategory = true,
        isMaas = false,
        fileList: FileList = null,
    ) {
        this.canSetProject = canSetProject;
        this.canSetSubCategory = canSetSubCategory;
        this.isMaas = isMaas;
        this.relatedObject = relatedObject;
        this.closeCallback = null;
        this.saveCallback = callback;
        this.existingJournal = null;
        this.droppedFiles = fileList;

        this.openDialog();
    }

    show(existingJournal: IJournal, onClose: () => void = null, canSetProject = false, canSetSubCategory = true) {
        this.readonly = true;
        this.canSetProject = canSetProject;
        this.canSetSubCategory = canSetSubCategory;
        this.existingJournal = existingJournal;
        this.relatedObject = null;
        this.closeCallback = onClose;
        this.saveCallback = null;

        this.openDialog();
    }

    edit(
        existingJournal: IJournal,
        relatedObject: JournalRelatedTypes,
        callback?: (result: IJournal) => void,
        canSetProject = false,
        canSetSubCategory = true,
        isMaas = false,
    ) {
        this.canSetProject = canSetProject;
        this.canSetSubCategory = canSetSubCategory;
        this.isMaas = isMaas;
        this.relatedObject = relatedObject;
        this.closeCallback = null;
        this.saveCallback = callback;
        this.droppedFiles = null;
        this.existingJournal = existingJournal;

        this.openDialog();
    }

    protected onOpen() {
        const projectControl = this.formBuilder.control(null);

        this.manageJournalEntryForm = this.formBuilder.group({
            timestamp: [new Date(), Validators.required],
            categoryId: ["", Validators.required],
            subCategoryId: this.canSetSubCategory ? ["", Validators.required] : null,
            remarks: null,
            projectId: projectControl,
        }) as SigncoFormGroup;

        const contextTypeId = this.getContextTypeId();
        const organizationId = contextTypeId === "organization" ? this.relatedObject.id : null;

        this.translationObject = {
            code: this.relatedObject
                ? (this.relatedObject as IOrganization).name || (this.relatedObject as IDevice | IMeasuringPoint).code
                : null,
        };

        this.domainModelFilterService.getProjects$(organizationId).then((projects) => {
            this.projects = projects;
        });

        this.domainModelFilterService.getJournalCategories$(contextTypeId, this.isMaas).then((journalCategories) => {
            this.categories = journalCategories;
        });

        if (this.globalEventsService.getCurrentRights()?.hasBackendRight(BackendRights.ManageAdminJournal)) {
            this.manageJournalEntryForm.addControl("adminRemarks", this.formBuilder.control(null));
            this.manageJournalEntryForm.addControl("isAdminOnly", this.formBuilder.control(false));
        }

        if (this.existingJournal) {
            this.manageJournalEntryForm.patchValue(this.existingJournal);

            if (this.existingJournal.project) {
                projectControl.patchValue(this.existingJournal.project.id);
            }

            const categoryId = this.existingJournal.category.id;
            this.manageJournalEntryForm.get("categoryId").setValue(categoryId);
            this.setSelectedCategory(categoryId);

            if (this.existingJournal.subCategory) {
                this.manageJournalEntryForm.get("subCategoryId").setValue(this.existingJournal.subCategory.id);
            }
        } else {
            this.setSelectedCategory(null);
            const projectId = this.filterService.filterState.projects?.takeFirstOrDefault()?.id;
            if (this.canSetProject && projectId && this.isMeasuringPoint(this.relatedObject))
                projectControl.setValue(+projectId);
        }

        if (this.readonly) {
            this.manageJournalEntryForm.disable();
        }
    }

    onShow() {
        super.onShow();

        // sometimes we're opening this dialog using drag-and-drop of files
        // in that case we're creating new journal so we can immediately load new files
        if (this.droppedFiles) {
            this.attachmentFormComponent.addAttachmentFormsFromInput({ files: this.droppedFiles });
        }
    }

    protected onClose() {
        if (this.closeCallback) {
            this.closeCallback();
        }

        this.manageJournalEntryForm = null;
        this.readonly = false;
    }

    setSelectedCategory(categoryId: number) {
        const subCategoryControl = this.manageJournalEntryForm.get("subCategoryId");
        subCategoryControl.setValue(null);

        if (!categoryId) {
            subCategoryControl.disable();
            this.subCategories = [];
            return;
        }

        this.domainModelFilterService.getJournalSubCategories$(categoryId).then((subCategories) => {
            this.subCategories = subCategories;

            if (!this.readonly) {
                subCategoryControl.enable();
            }
        });
    }

    private getRouteParams(): { [index: string]: string } {
        if (!this.relatedObject) return null;

        return { type: this.getContextTypeId(), id: this.relatedObject.id.toString() };
    }

    private getContextTypeId(): "device" | "measuringPoint" | "organization" | "project" | "assignment" {
        if (!this.relatedObject) return null;

        return this.isDevice(this.relatedObject)
            ? "device"
            : this.isMeasuringPoint(this.relatedObject)
              ? "measuringPoint"
              : this.isOrganization(this.relatedObject)
                ? "organization"
                : this.isAssignment(this.relatedObject)
                  ? "assignment"
                  : "project";
    }

    async submit() {
        if (this.readonly) {
            this.close();
            return;
        }

        const isValid = await this.formValidationService.checkValidity(this.manageJournalEntryForm);
        if (!isValid) return;

        this.submitting = true;

        const onSucces = async (savedJournalEntry: IJournal) => {
            if (this.attachmentFormComponent) {
                await this.attachmentFormComponent.uploadAttachments(savedJournalEntry, "journalId");
            }

            if (this.saveCallback) {
                this.saveCallback(savedJournalEntry);
            }

            this.submitting = false;
            this.close();
        };

        const onError = (error: any) => {
            this.submitting = false;
        };

        if (!this.existingJournal) {
            const creator = new JournalCreator();
            Object.assign(creator, this.manageJournalEntryForm.value);

            this.journalApi.create$(creator, this.getRouteParams()).subscribe(onSucces, onError);
        } else {
            const updater = new JournalUpdater(this.existingJournal);
            Object.assign(updater, this.manageJournalEntryForm.value);

            this.journalApi.update$(updater, this.getRouteParams()).subscribe(onSucces, onError);
        }
    }

    isDevice(object: JournalRelatedTypes): object is IDevice {
        return "isDevice" in object;
    }

    isMeasuringPoint(object: JournalRelatedTypes): object is IMeasuringPoint {
        return "isMeasuringPoint" in object;
    }

    isOrganization(object: JournalRelatedTypes): object is IOrganization {
        return "isOrganization" in object;
    }

    isAssignment(object: JournalRelatedTypes): object is IAssignment {
        return "isAssignment" in object;
    }
}
