import { Injectable } from "@angular/core";
import { IMeasuringPoint } from "@ramudden/models/measuring-point";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class MeasuringPointService {
    protected currentMeasuringPoint: IMeasuringPoint;
    measuringPointSubscription$: BehaviorSubject<IMeasuringPoint> = new BehaviorSubject(null);

    notifyMeasuringPointChange(measuringPoint: IMeasuringPoint) {
        this.currentMeasuringPoint = measuringPoint;
        this.measuringPointSubscription$.next(measuringPoint);
    }
}
