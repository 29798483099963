import { ApiServiceBase } from "./api";
import { HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class MetaApi extends ApiServiceBase {
    override getRoute(): string {
        return "Meta";
    }

    version$(useCache = true): Observable<{ version: string }> {
        const url = `${this.getUrl()}/Version`;

        if (useCache) {
            return this.handleCaching(url);
        }

        return this.http.get<{ version: string }>(url);
    }

    status$(): Observable<HttpResponse<void>> {
        const url = `${this.getUrl()}/Status`;

        return this.http.get<HttpResponse<void>>(url);
    }
}
