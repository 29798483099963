import { Component, Input } from "@angular/core";
import { SigncoFormGroup } from "@ramudden/models/form";
import { DomainDataService } from "../../../services/domain-data.service";

@Component({
    selector: "app-model-translation-form",
    templateUrl: "./model-translation-form.component.html",
})
export class ModelTranslationFormComponent {
    @Input() parentFormGroup: SigncoFormGroup;
    @Input() formGroupName: string;
    @Input() controlLabel: string;
    @Input() autofocus = false;
    @Input() multiline = false;

    languages: string[];

    constructor(private readonly domainDataService: DomainDataService) {
        this.languages = this.domainDataService.getLanguages();
    }
}
