import { IAssignment } from "./assignment";
import { IAttachment } from "./attachment";
import { ILocation, LocationCreator } from "./location";
import { IServiceModelBase } from "./servicemodelbase";
import { IWorkerBase } from "./worker";

export interface ITask extends IServiceModelBase {
    name: string;
    description: string;
    start?: Date;
    end?: Date;

    assignment: IAssignment;
    location: ILocation;

    copiedTask?: ITask;
    currentStatus: ITaskStatusHistory;
    statusHistory: ITaskStatusHistory[];
    attachments?: IAttachment[];
}

export interface ITaskStatusHistory {
    id: number;
    timestamp: Date;
    worker: IWorkerBase;
    task: ITask;

    isMachineWork: boolean;
    isNew: boolean;
    isVerified: boolean;
    primer: boolean;

    taskStatusId: TaskStatus;
    availableFrom: Date;
    workerTasks: IWorkerTask[];
    taskTypeId: TaskType;
    signMaterialId: SignMaterial;
    signCategoryId: SignCategoryValue;
    signCategoryLevel2Id?: SignCategoryValue;
    signCategoryLevel3Id?: SignCategoryValue;
    signCategoryLevel4Id?: SignCategoryValue;
    substrateTypeId: SubstrateType;
    substrateTypeOverride: string;

    hoursCount: number;

    note: string;
    customSignColor: string;
    signColorId: SignColorType;
    markingsCount: number;
    length: number;
    lengthUnitId: Unit;
    width: number;
    widthUnitId: Unit;
    coloredLength: number;
    coloredLengthUnit: Unit;
    coloredWidth: number;
    coloredWidthUnit: Unit;
    dimensionWidth: number;
    dimensionLength: number;
    dimensionUnitId: Unit;
    dimensionDescription: string;
    type: MarkingPropertyType;
    bannerCount: number;
    logoCount: number;
    diameter: number;
    diameterUnitId: Unit;
    tElementWidth: number;
    tElementLength: number;
    tElementUnitId: Unit;
    lElementWidth: number;
    lElementLength: number;
    lElementUnitId: Unit;
    tElementCount: number;
    lElementCount: number;
    tramElementCount: number;
    height: number;
    heightUnitId: Unit;
    text: string;
    speed: number;
    placenumber: string;
    block1Amount: number;
    block1Width: number;
    block2Amount: number;
    block2Width: number;
    blocksWidthUnitId: Unit;
    lengthA: number;
    lengthB: number;
    lengthC: number;
    lengthD: number;
    lengthABCDUnitId: Unit;
    whiteSubstrateLength: number;
    whiteSubstrateLengthUnitId: Unit;
    whiteSubstrateWidth: number;
    whiteSubstrateWidthUnitId: Unit;
    whiteBandCount: number;
    redBandCount: number;
    yellowBandCount: number;
    orangeBandCount: number;
    greenBandCount: number;
    blueBandCount: number;
    purpleBandCount: number;
    bendCount: number;
    arrowHeadCount: number;
    roundedEndCount: number;
    shortPieceCount: number;
    propertyConfiguration: TaskPropertyConfiguration;
}

export interface IWorkerTask {
    worker: IWorkerBase;
    taskStatusHistory: ITaskStatusHistory;
}

export class TaskCreator {
    name: string;
    description: string;
    start?: Date;
    end?: Date;

    assignmentId?: number;
    copiedTaskId?: number;
    isRecurring: boolean;
    locationId?: number;
    location?: LocationCreator;

    currentStatus: TaskStatusHistoryCreator;
}

export class TaskStatusHistoryCreator {
    isMachineWork?: boolean;
    isNew?: boolean;
    isVerified: boolean;
    primer: boolean;

    taskStatusId: TaskStatus;

    availableFrom: Date;
    workerTasks: WorkerTaskCreator[];

    taskTypeId: TaskType;
    signMaterialId: SignMaterial;
    signCategoryId: SignCategoryValue;
    substrateTypeId: SubstrateType;
    signCategoryLevel2Id?: SignCategoryValue;
    signCategoryLevel3Id?: SignCategoryValue;
    signCategoryLevel4Id?: SignCategoryValue;

    substrateTypeOverride: string;

    hoursCount: number;
    note: string;
    customSignColor: string;
    signColorId: SignColorType;
    markingsCount: number;
    length: number;
    lengthUnitId: Unit;
    width: number;
    widthUnitId: Unit;
    coloredLength: number;
    coloredLengthUnit: Unit;
    coloredWidth: number;
    coloredWidthUnit: Unit;
    dimensionWidth: number;
    dimensionLength: number;
    dimensionDescription: string;
    dimensionUnitId: Unit;
    type: MarkingPropertyType;
    bannerCount: number;
    logoCount: number;
    diameter: number;
    diameterUnitId: Unit;
    tElementWidth: number;
    tElementLength: number;
    tElementUnitId: Unit;
    lElementWidth: number;
    lElementLength: number;
    lElementUnitId: Unit;
    tElementCount: number;
    lElementCount: number;
    tramElementCount: number;
    height: number;
    heightUnitId: Unit;
    text: string;
    speed: number;
    placenumber: string;
    block1Amount: number;
    block2Amount: number;
    block1Width: number;
    block2Width: number;
    lengthA: number;
    lengthB: number;
    lengthC: number;
    lengthD: number;
    blocksWidthUnitId: Unit;
    lengthABCDUnitId: Unit;
    whiteSubstrateLength: number;
    whiteSubstrateLengthUnitId: Unit;
    whiteSubstrateWidth: number;
    whiteSubstrateWidthUnitId: Unit;
    whiteBandCount: number;
    redBandCount: number;
    yellowBandCount: number;
    orangeBandCount: number;
    greenBandCount: number;
    blueBandCount: number;
    purpleBandCount: number;
    bendCount: number;
    arrowHeadCount: number;
    roundedEndCount: number;
    shortPieceCount: number;

    propertyConfiguration: TaskPropertyConfiguration;

    init(taskStatus: ITaskStatusHistory): TaskStatusHistoryCreator {
        this.markingsCount = taskStatus.markingsCount;
        this.isMachineWork = taskStatus.isMachineWork;
        this.isNew = taskStatus.isNew;
        this.isVerified = taskStatus.isVerified;
        this.primer = taskStatus.primer;

        this.taskStatusId = taskStatus.taskStatusId;

        this.availableFrom = taskStatus.availableFrom;
        this.workerTasks = taskStatus?.workerTasks?.map((el) => {
            const creator = ({} as WorkerTaskCreator).init(el);
            return creator;
        });

        this.taskTypeId = taskStatus.taskTypeId;
        this.signMaterialId = taskStatus.signMaterialId;
        this.signCategoryId = taskStatus.signCategoryId;
        this.signCategoryLevel2Id = taskStatus.signCategoryLevel2Id;
        this.signCategoryLevel3Id = taskStatus.signCategoryLevel3Id;
        this.signCategoryLevel4Id = taskStatus.signCategoryLevel4Id;
        this.substrateTypeId = taskStatus.substrateTypeId;
        this.substrateTypeOverride = taskStatus.substrateTypeOverride;

        this.propertyConfiguration = taskStatus.propertyConfiguration;

        this.hoursCount = taskStatus.propertyConfiguration.showHoursCount ? taskStatus.hoursCount : undefined;
        this.note = taskStatus.propertyConfiguration.showNote ? taskStatus.note : undefined;
        this.customSignColor = taskStatus.customSignColor;
        this.signColorId = taskStatus.propertyConfiguration.showSignColorList ? taskStatus.signColorId : undefined;
        this.markingsCount = taskStatus.propertyConfiguration.showMarkingsCount ? taskStatus.markingsCount : undefined;
        this.length = taskStatus.propertyConfiguration.showLength ? taskStatus.length : undefined;
        this.lengthUnitId = taskStatus.propertyConfiguration.showLength ? taskStatus.lengthUnitId : undefined;
        this.width = taskStatus.propertyConfiguration.showWidth ? taskStatus.width : undefined;
        this.widthUnitId = taskStatus.propertyConfiguration.showWidth ? taskStatus.widthUnitId : undefined;
        this.coloredLength = taskStatus.propertyConfiguration.showColoredLength ? taskStatus.coloredLength : undefined;
        this.coloredLengthUnit = taskStatus.propertyConfiguration.showColoredLength
            ? taskStatus.coloredLengthUnit
            : undefined;
        this.coloredWidth = taskStatus.propertyConfiguration.showColoredWidth ? taskStatus.coloredWidth : undefined;
        this.coloredWidthUnit = taskStatus.propertyConfiguration.showColoredWidth
            ? taskStatus.coloredWidthUnit
            : undefined;
        this.dimensionWidth = taskStatus.propertyConfiguration.showDimension ? taskStatus.dimensionWidth : undefined;
        this.dimensionLength = taskStatus.propertyConfiguration.showDimension ? taskStatus.dimensionLength : undefined;
        this.dimensionDescription = taskStatus.propertyConfiguration.showDimension
            ? taskStatus.dimensionDescription
            : undefined;
        this.dimensionUnitId = taskStatus.propertyConfiguration.showDimension ? taskStatus.dimensionUnitId : undefined;
        this.type = taskStatus.propertyConfiguration.showType ? taskStatus.type : undefined;
        this.bannerCount = taskStatus.propertyConfiguration.showBannerCount ? taskStatus.bannerCount : undefined;
        this.logoCount = taskStatus.propertyConfiguration.showLogoCount ? taskStatus.logoCount : undefined;
        this.diameter = taskStatus.propertyConfiguration.showDiameter ? taskStatus.diameter : undefined;
        this.tElementWidth = taskStatus.propertyConfiguration.showTElementDimension
            ? taskStatus.tElementWidth
            : undefined;
        this.tElementLength = taskStatus.propertyConfiguration.showTElementDimension
            ? taskStatus.tElementLength
            : undefined;
        this.tElementUnitId = taskStatus.propertyConfiguration.showTElementDimension
            ? taskStatus.tElementUnitId
            : undefined;
        this.lElementUnitId = taskStatus.propertyConfiguration.showLElementDimension
            ? taskStatus.lElementUnitId
            : undefined;
        this.lElementWidth = taskStatus.propertyConfiguration.showLElementDimension
            ? taskStatus.lElementWidth
            : undefined;
        this.lElementLength = taskStatus.propertyConfiguration.showLElementDimension
            ? taskStatus.lElementLength
            : undefined;
        this.tElementCount = taskStatus.propertyConfiguration.showTElementCount ? taskStatus.tElementCount : undefined;
        this.lElementCount = taskStatus.propertyConfiguration.showLElementCount ? taskStatus.lElementCount : undefined;
        this.tramElementCount = taskStatus.propertyConfiguration.showTRAMElementCount
            ? taskStatus.tramElementCount
            : undefined;
        this.height = taskStatus.propertyConfiguration.showHeight ? taskStatus.height : undefined;
        this.heightUnitId = taskStatus.propertyConfiguration.showHeight ? taskStatus.heightUnitId : undefined;
        this.text = taskStatus.propertyConfiguration.showText ? taskStatus.text : undefined;
        this.speed = taskStatus.propertyConfiguration.showSpeed ? taskStatus.speed : undefined;
        this.placenumber = taskStatus.propertyConfiguration.showPlacenumber ? taskStatus.placenumber : undefined;
        this.block1Amount = taskStatus.propertyConfiguration.showBlock1Amount ? taskStatus.block1Amount : undefined;
        this.block2Amount = taskStatus.propertyConfiguration.showBlock2Amount ? taskStatus.block2Amount : undefined;
        this.lengthA = taskStatus.propertyConfiguration.showLengthA ? taskStatus.lengthA : undefined;
        this.lengthB = taskStatus.propertyConfiguration.showLengthB ? taskStatus.lengthB : undefined;
        this.lengthC = taskStatus.propertyConfiguration.showLengthC ? taskStatus.lengthC : undefined;
        this.lengthD = taskStatus.propertyConfiguration.showLengthD ? taskStatus.lengthD : undefined;
        this.lengthABCDUnitId = taskStatus.propertyConfiguration.showLengthD ? taskStatus.lengthABCDUnitId : undefined;
        this.whiteSubstrateLength = taskStatus.propertyConfiguration.showWhiteSubstrateLength
            ? taskStatus.whiteSubstrateLength
            : undefined;
        this.whiteSubstrateWidth = taskStatus.propertyConfiguration.showWhiteSubstrateWidth
            ? taskStatus.whiteSubstrateWidth
            : undefined;
        this.whiteSubstrateLengthUnitId = taskStatus.propertyConfiguration.showWhiteSubstrateLength
            ? taskStatus.whiteSubstrateLengthUnitId
            : undefined;
        this.whiteSubstrateWidthUnitId = taskStatus.propertyConfiguration.showWhiteSubstrateWidth
            ? taskStatus.whiteSubstrateWidthUnitId
            : undefined;
        this.whiteBandCount = taskStatus.propertyConfiguration.showWhiteBandCount
            ? taskStatus.whiteBandCount
            : undefined;
        this.redBandCount = taskStatus.propertyConfiguration.showRedBandCount ? taskStatus.redBandCount : undefined;
        this.yellowBandCount = taskStatus.propertyConfiguration.showYellowBandCount
            ? taskStatus.yellowBandCount
            : undefined;
        this.orangeBandCount = taskStatus.propertyConfiguration.showOrangeBandCount
            ? taskStatus.orangeBandCount
            : undefined;
        this.greenBandCount = taskStatus.propertyConfiguration.showGreenBandCount
            ? taskStatus.greenBandCount
            : undefined;
        this.blueBandCount = taskStatus.propertyConfiguration.showBlueBandCount ? taskStatus.blueBandCount : undefined;
        this.purpleBandCount = taskStatus.propertyConfiguration.showPurpleBandCount
            ? taskStatus.purpleBandCount
            : undefined;
        this.bendCount = taskStatus.propertyConfiguration.showBendCount ? taskStatus.bendCount : undefined;
        this.arrowHeadCount = taskStatus.propertyConfiguration.showArrowHeadCount
            ? taskStatus.arrowHeadCount
            : undefined;
        this.roundedEndCount = taskStatus.propertyConfiguration.showRoundedEndCount
            ? taskStatus.roundedEndCount
            : undefined;
        this.shortPieceCount = taskStatus.propertyConfiguration.showShortPieceCount
            ? taskStatus.shortPieceCount
            : undefined;
        return this;
    }
}

export class WorkerTaskCreator {
    workerId: number;
    taskStatusHistoryId?: number;

    init(workerTask: IWorkerTask) {
        this.workerId = workerTask.worker.id;
        this.taskStatusHistoryId = workerTask.taskStatusHistory.id;
        return this;
    }
}

export class TaskUpdater extends TaskCreator {
    id: number;

    init(task: ITask): TaskUpdater {
        this.id = task.id;
        this.name = task.name;
        this.description = task.description;
        this.start = task.start;
        this.end = task.end;

        this.assignmentId = task.assignment ? task.assignment.id : undefined;
        this.locationId = task.location ? task.location.id : undefined;

        this.currentStatus = new TaskStatusHistoryCreator().init(task.currentStatus);

        return this;
    }
}

export class TaskPropertyConfiguration {
    showSignColorList: boolean;
    showNote: boolean;
    showHoursCount: boolean;
    showMinutesCount: boolean;
    showMarkingsCount: boolean;
    showLength: boolean;
    showWidth: boolean;
    showColoredLength: boolean;
    showColoredWidth: boolean;
    showDimension: boolean;
    showType: boolean;
    showBannerCount: boolean;
    showLogoCount: boolean;
    showImage: boolean;
    showDiameter: boolean;
    showTElementDimension: boolean;
    showLElementDimension: boolean;
    showTElementCount: boolean;
    showLElementCount: boolean;
    showTRAMElementCount: boolean;
    showHeight: boolean;
    showText: boolean;
    showSpeed: boolean;
    showPlacenumber: boolean;
    showBlock1Amount: boolean;
    showBlock2Amount: boolean;
    showBlock1Width: boolean;
    showBlock2Width: boolean;
    showLengthA: boolean;
    showLengthB: boolean;
    showLengthC: boolean;
    showLengthD: boolean;
    showWhiteSubstrateLength: boolean;
    showWhiteSubstrateWidth: boolean;
    showWhiteBandCount: boolean;
    showRedBandCount: boolean;
    showYellowBandCount: boolean;
    showOrangeBandCount: boolean;
    showGreenBandCount: boolean;
    showBlueBandCount: boolean;
    showPurpleBandCount: boolean;
    showBendCount: boolean;
    showArrowHeadCount: boolean;
    showRoundedEndCount: boolean;
    showShortPieceCount: boolean;
    defaultCustomSignColor: string;
    imageName: string;
    widths: PrimitiveOption[];
    lengths: PrimitiveOption[];
    heights: PrimitiveOption[];
    coloredWidths: PrimitiveOption[];
    coloredLenghts: PrimitiveOption[];
    block1Widths: PrimitiveOption[];
    block2Widths: PrimitiveOption[];
    diameters: PrimitiveOption[];
    speeds: PrimitiveOption[];
    texts: PrimitiveOption[];
    types: MarkingPropertyTypeOption[];
    tElementDimensions: DimensionOption[];
    lElementDimensions: DimensionOption[];
    dimensions: DimensionOption[];
    signColors: SignColorOption[];

    lengthUnit: Unit;
    widthUnit: Unit;
    dimensionUnit: Unit;
    diameterUnit: Unit;
    blocksWidthUnit: Unit;
    heightUnit: Unit;
    coloredLengthUnit: Unit;
    coloredWidthUnit: Unit;
    tElementUnit: Unit;
    lElementUnit: Unit;
    lengthABCDUnit: Unit;
    whiteSubstrateLengthUnit: Unit;
    whiteSubstrateWidthUnit: Unit;
}

export class Option {
    standard: boolean;
}
export class PrimitiveOption extends Option {
    value: any;
}
export class DimensionOption extends Option {
    length: number;
    width: number;
    description: string;
}

export class MarkingPropertyTypeOption extends Option {
    value: MarkingPropertyType;
}
export class SignColorOption extends Option {
    value: SignColorType;
}
export enum TaskType {
    TemporaryMarking = "temporaryMarking",
    PermanentMarking = "permanentMarking",
    PressureWashingMarking = "pressureWashingMarking",
    Control = "control",
}

export enum MarkingPropertyType {
    Straight = "straight",
    Slanted = "slanted",
    Axis = "axis",
    EdgeRoadway = "edgeRoadway",
    EdgeHatching = "edgeHatching",
    ParkingLine = "parkingLine",
    Cambio = "cambio",
    AntwerpType = "antwerpType",
    Valckenier = "valckenier",
    Other = "other",
    Dimension1050mmx1050mm = "dimension1050mmx1050mm",
    Dimension1000mmx1000mm = "dimension1000mmx1000mm",
    Dimension1500x1200Colored = "dimension1500x1200Colored",
    Begin = "begin",
    End = "end",
    White = "white",
    Colored = "colored",
    Left = "left",
    Right = "right",
    Big = "big",
    Small = "small",
}
export enum Unit {
    Meter = "meter",
    Kilometer = "kilometer",
    Centimeter = "centimeter",
    Millimeter = "millimeter",
}

export enum SignMaterial {
    Tape = "tape",
    Paint = "paint",
    Primark = "primark",
    ThermoPlastic = "thermoPlastic",
    ColdPlastic = "coldPlastic",
}

export enum SignCategoryValue {
    Lines = "lines",
    Arrows = "arrows",
    Hatching = "hatching",
    StopLines = "stopLines",
    Transitions = "transitions",
    Text = "text",
    Tram = "tram",
    Bus = "bus",
    Taxi = "taxi",
    Logos = "logos",
    MivbLogos = "mivbLogos",
    Surfaces = "surfaces",
    Others = "others",
}

export enum SignColorType {
    White = "white",
    Colored = "colored",
    Black = "black",
    Red = "red",
}

export enum SubstrateType {
    Asphalt = "asphalt",
    Concrete = "concrete",
    Clinkers = "clinkers",
    Cobblestone = "cobblestone",
    Others = "others",
}

export enum TaskStatus {
    InProgress = "inProgress",
    Finished = "finished",
    Scheduled = "scheduled",
    OnHold = "onHold",
}
