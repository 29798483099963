import { Duration } from "moment";
import { IAlarm } from "./alarm";
import { IAttachment } from "./attachment";
import { ICameraConfiguration } from "./camera";
import { IDateTimeRange } from "./datetime-range";
import { IDekimoGuiConfiguration } from "./dekimo-gui-configuration";
import { IDekimoTrackerConfiguration } from "./dekimo-tracker-configuration";
import { IAvsConfiguration } from "./device-avs";
import { IDeviceDepotNavigator } from "./device-depot";
import { IDekimoDisplayConfiguration, IDeviceDisplayConfiguration } from "./device-display-configuration";
import { ILisaConfiguration } from "./device-lisa";
import { IRelayConfiguration } from "./device-relay";
import { ILocation } from "./location";
import { IMeasuringPointNavigator } from "./measuring-point";
import { ICommit, IOccuredException } from "./meta";
import { IRelease, ReleaseCreator } from "./release";
import { IServiceModelBase } from "./servicemodelbase";
import { ITmsRadarConfiguration } from "./tms-radar-configuration";
import { AnalyzerConfiguration } from "./upload";
import { IUserSummary } from "./user";
import { IVehicleConfiguration } from "./vehicle-configuration";
import { IVmsStatus } from "./vms-status";
import { IMeasuringPointSummary } from "./web";
import { IWorkerBase } from "./worker";
import { IZigbeeConfiguration } from "./zigbee-configuration";

export enum DeviceType {
    PulseMeasuringFixed = "pulseMeasuringFixed",
    PulseMeasuringConnected = "pulseMeasuringConnected",
    PulseMeasuringNotConnected = "pulseMeasuringNotConnected",
    BikeCounter = "bikeCounter",
    Anpr = "anpr",
    Camera = "camera",
    ControlPanel = "controlPanel",
    TrafficFleet = "trafficFleet",
    AvsVms = "avsVms",
    AvsRetroTrafficLight = "avsRetroTrafficLight",
    AvsTrafficLight = "avsTrafficLight",
    AvsFleetTracker = "avsFleetTracker",
    Relay = "relay",
    DekimoController = "dekimoController",
    DekimoTracker = "dekimoTracker",
    GenericLinuxController = "genericLinuxController",
    Vehicle = "vehicle",
    SigncoTablet = "signcoTablet",
    WJet = "wJet",
    SmartSolarTracker = "smartSolarTracker",
    Tat140Tracker = "tat140Tracker",
    TssTrailerTracker = "tssTrailerTracker",
    Lisa = "lisa",
}

export interface IDeviceNavigator extends IServiceModelBase {
    code: string;
    typeId: DeviceType;
    ownerId: number;
}

export interface IDeviceSummary extends IDeviceNavigator {
    currentLocationId: number;
    description: string;
    manufacturer: string;
    measuringPoints: IMeasuringPointNavigator[];
    alarms: IAlarm[];
    alarmsHigh: number[];
    alarmsLow: number[];
    cameraConfigurationImage: string;
    depot: IDeviceDepotNavigator;
}

export interface ILinkMeasurement extends IServiceModelBase {
    measuringPoint: IMeasuringPointNavigator;
    measuringPointSummary: IMeasuringPointSummary;
    analyzerConfiguration?: AnalyzerConfiguration;
    tubes?: string[]; // Purely as a shortcut
}

export interface IDeviceLink extends IServiceModelBase {
    id: number;
    validFrom: Date;
    validUntil?: Date;
    device: IDeviceNavigator;
    creator: IUserSummary;
    gapfiller: boolean;
    measurements: ILinkMeasurement[];
    depot: IDeviceDepotNavigator;
}

export interface IDeviceLinkConflicts {
    conflicts: IDeviceLinkConflict[];
    newLink: IDeviceLink;
}

export interface IDeviceLinkConflict {
    original: IDeviceLink;
    changed: IDateTimeRange;
    showChangedFrom: boolean;
    showChangedUntil: boolean;
}

export interface IPowerStatus {
    batteryVoltage: number;
    chargePercentage: number;
    isConnectedToPowerSupply: boolean;
}

export interface ITrafficLightStatus {
    state: TrafficLightState;
    head: TrafficLightHead;
}

export enum TrafficLightHead {
    Unknown = "unknown",
    K1 = "k1",
    K2 = "k2",
    K3 = "k3",
    K4 = "k4",
}

export enum TrafficLightState {
    Unknown = "unknown",
    Green = "green",
    GreenBlinking = "greenBlinking",
    Orange = "orange",
    OrangeBlinking = "orangeblinking",
    Red = "red",
    RedBlinking = "redBlinking",
    RedOrange = "redOrange",
    Off = "off",
}

export interface IArrowBoardStatus {
    position: ArrowBoardPosition;
    direction: ArrowBoardDirection;
}

export enum ArrowBoardPosition {
    Open = "open",
    Closed = "closed",
}

export enum ArrowBoardDirection {
    Left = "left",
    Right = "right",
    Cross = "cross",
    None = "none",
}
export interface ICounterStatus {
    dayCounter: number;
    monthCounter: number;
    yearCounter: number;
}

export interface IFirmwareVersion {
    major: number;
    minor: number;
}

export interface IEnvironmentStatus {
    internalTemperature?: number;
    externalTemperature?: number;
}

export interface IControllerStatus {
    availableDiskSpace?: number;
    firmwareVersion?: IFirmwareVersion;
    softwareVersion?: string;
    configurationTimestamp: Date;
    versions: string[];
}

export interface IZigbeeLostDevice {
    timestamp: Date;
    code: string;
}

export interface IZigbeeStatus {
    isConnectedToNetwork: boolean;
    packetsReceived: number;
    packetsAttempted: number;
    packetsSent: number;
    packetsLost: number;
    packetTravelTimeAverageInMs: number;
    packetTravelTimeMaxInMs: number;
    packetTravelTimeMinInMs: number;
    packetLossPerc: number;
    dbm: number;
    lostDevices: IZigbeeLostDevice[];
    errors: string;
}

export interface IDekimoFacade {
    displayedText: string;
    errors: string[];
    commit: ICommit;
    startDate: Date;
    lastException: IOccuredException;
    zigbeeStatus: IZigbeeStatus;
}

export interface IDeviceStatus {
    timestamp: Date;
    url: string;
    powerStatus: IPowerStatus;
    counterStatus: ICounterStatus;
    controller: IControllerStatus;
    environment: IEnvironmentStatus;
    imageDisplay: IVmsStatus;
    networkStatus: INetworkStatus;
    dekimoFacade: IDekimoFacade;
    errors: string[];
}

export interface INetworkStatus {
    technology: string;
    ipAddress: string;
    operator: string;
    signalStrength: number;
    iccid: number;
}

export interface IAnprConfiguration {
    dataUrl: string;
    updateInterval: Duration;
}

export interface IDeviceFeatures {
    zigbee: boolean;
    gpio: boolean;
    otaUpdate: boolean;
    realtimeAnalyzer: boolean;
    vms: boolean;
    qLiteDisplay: boolean;
    dekimoDisplay: boolean;
    tmsRadar: boolean;
    simulateData: boolean;
}

export interface IRealtimeAnalyzerConfiguration {
    maxWindowTime: Duration;
    minTimeBetweenWheelies: Duration;
    simulateVehicleOnEveryPulse: boolean;
}

export interface IDeviceIgnoredRule {
    ruleId: number;
    until: Date;
    comment: string;
}

export interface IDeviceReleaseConfiguration {
    releaseChannelId: string;
    packageId: string;
    specificReleaseId: number;
    specificRelease: ReleaseCreator;
}

export interface IDevice extends IServiceModelBase {
    id: number;
    code: string;
    description: string;
    manufacturer: string;
    typeId: DeviceType;
    features: IDeviceFeatures;
    ownerId: number;
    currentLink: IDeviceLink;
    currentStatus: IDeviceStatus;
    lastSync: Date;
    currentLocation: ILocation;
    isOffline: boolean;
    useRealtimeUpdates: boolean;
    activeReleases: IRelease[];
    batteryDischargeTableId: number;
    imei: string;
    ipAddress: string;
    qrCode: string;

    ignoredRules: IDeviceIgnoredRule[];
    alarms: IAlarm[];
    alarmLevel: string[];

    releaseConfigurations: IDeviceReleaseConfiguration[];
    anprConfiguration: IAnprConfiguration;
    avsConfiguration: IAvsConfiguration;
    lisaConfiguration: ILisaConfiguration;
    displayConfiguration: IDeviceDisplayConfiguration;
    dekimoDisplayConfiguration: IDekimoDisplayConfiguration;
    cameraConfiguration: ICameraConfiguration;
    relayConfiguration: IRelayConfiguration;
    zigbeeConfiguration: IZigbeeConfiguration;
    tmsRadarConfiguration: ITmsRadarConfiguration;
    realtimeAnalyzerConfiguration: IRealtimeAnalyzerConfiguration;
    dekimoGuiConfiguration: IDekimoGuiConfiguration;
    hardwareConfiguration: string;
    dekimoTrackerConfiguration: IDekimoTrackerConfiguration;
    vehicleConfiguration: IVehicleConfiguration;

    workerDriver?: IWorkerBase;
    attachment?: IAttachment;
}

export class DeviceCreator {
    code: string;
    description: string;
    manufacturer: string;
    typeId: DeviceType;
    features: IDeviceFeatures;
    ownerId?: number;
    ignoredRules: IDeviceIgnoredRule[];
    isOffline: boolean;
    imei: string;
    ipAddress: string;
    qrCode: string;
    useRealtimeUpdates: boolean;
    dekimoGuiConfiguration: IDekimoGuiConfiguration;
    hardwareConfiguration: string;
    dekimoTrackerConfiguration: IDekimoTrackerConfiguration;
    batteryDischargeTableId: number;
    releaseConfigurations: IDeviceReleaseConfiguration[];

    workerDriverId?: number;
}

export class SpecificReleaseCreator {
    release: ReleaseCreator;
    releaseId: number;
}

export class DeviceUpdater extends DeviceCreator {
    constructor(existingDevice: IDevice) {
        super();

        this.id = existingDevice.id;
        this.code = existingDevice.code;
        this.description = existingDevice.description;
        this.manufacturer = existingDevice.manufacturer;
        this.typeId = existingDevice.typeId;
        this.features = existingDevice.features;
        this.ownerId = existingDevice.ownerId;
        this.isOffline = existingDevice.isOffline;
        this.imei = existingDevice.imei;
        this.batteryDischargeTableId = existingDevice.batteryDischargeTableId;

        this.ignoredRules = structuredClone(existingDevice.ignoredRules || []);

        this.releaseConfigurations = structuredClone(existingDevice.releaseConfigurations || []);

        this.anprConfiguration = existingDevice.anprConfiguration;
        this.avsConfiguration = existingDevice.avsConfiguration;
        this.lisaConfiguration = existingDevice.lisaConfiguration;
        this.displayConfiguration = existingDevice.displayConfiguration;
        this.dekimoDisplayConfiguration = existingDevice.dekimoDisplayConfiguration;
        this.cameraConfiguration = existingDevice.cameraConfiguration;
        this.relayConfiguration = existingDevice.relayConfiguration;
        this.zigbeeConfiguration = existingDevice.zigbeeConfiguration;
        this.tmsRadarConfiguration = existingDevice.tmsRadarConfiguration;
        this.dekimoGuiConfiguration = existingDevice.dekimoGuiConfiguration;
        this.hardwareConfiguration = existingDevice.hardwareConfiguration;
        this.realtimeAnalyzerConfiguration = existingDevice.realtimeAnalyzerConfiguration;
        this.useRealtimeUpdates = existingDevice.useRealtimeUpdates;
        this.vehicleConfiguration = existingDevice.vehicleConfiguration;

        this.workerDriverId = existingDevice.workerDriver?.id;
    }

    id: number;
    anprConfiguration: IAnprConfiguration;
    avsConfiguration: IAvsConfiguration;
    lisaConfiguration: ILisaConfiguration;
    displayConfiguration: IDeviceDisplayConfiguration;
    dekimoDisplayConfiguration: IDekimoDisplayConfiguration;
    cameraConfiguration: ICameraConfiguration;
    relayConfiguration: IRelayConfiguration;
    zigbeeConfiguration: IZigbeeConfiguration;
    tmsRadarConfiguration: ITmsRadarConfiguration;
    realtimeAnalyzerConfiguration: IRealtimeAnalyzerConfiguration;
    specificRelease: SpecificReleaseCreator;
    vehicleConfiguration: IVehicleConfiguration;
}

export class LinkMeasurementCreator {
    constructor(existing: ILinkMeasurement = null) {
        if (!existing) return;
        this.measuringPointId = existing.measuringPoint.id;
        this.analyzerConfiguration = existing.analyzerConfiguration;
    }

    measuringPointId: number;
    analyzerConfiguration: AnalyzerConfiguration;
}

export class DeviceLinkCreator {
    validFrom: Date;
    validUntil?: Date;
    deviceId: number;
    preview?: boolean;
    depotId: number;
    measurements: LinkMeasurementCreator[];
}

export class DeviceLinkUpdater extends DeviceLinkCreator {
    id: number;

    constructor(existing: IDeviceLink) {
        super();

        this.id = existing.id;
        this.validFrom = existing.validFrom;
        this.validUntil = existing.validUntil;
        this.deviceId = existing.device.id;
        this.depotId = existing.depot ? existing.depot.id : null;
        this.measurements = existing.measurements
            ? existing.measurements.map((x) => new LinkMeasurementCreator(x))
            : [];
    }
}

export interface IBatteryHistory {
    timestamp: Date;
    batteryVoltage?: number;
    chargePercentage?: number;
}

export interface IDeviceLastLocationsCompared {
    gpsLocation?: ILocation;
    gpsAddress?: string;
    timestampGps?: Date;
    linkedLocation?: ILocation;
    distanceInMeter?: number;
    cellTowerLocation?: ILocation;
    error: boolean;
}
