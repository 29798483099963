import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { ParkingBanRedirectComponent } from "./pages/assignments/assignment-container/assignment-parking-bans/parking-ban-redirect/parking-ban-redirect.component";
import { TaskRedirectComponent } from "./pages/assignments/assignment-container/assignment-tasks/task-redirect/task-redirect.component";
import { ServiceStatusComponent } from "./pages/service-status/service-status.component";

const routes: Routes = [
    //region Redirects

    // Remain compatible with the old /map/measuringpoints route
    { path: "map/measuringpoint/:locationId/:mpId/:tab", redirectTo: "locations/:locationId/:mpId/:tab" },

    // Remain compatible with the old /map/assignments route
    { path: "map/assignments", redirectTo: "assignments" },
    { path: "map/assignments/:assignmentId/parking-bans", redirectTo: "assignments/:assignmentId/parkingbans" },
    { path: "map/assignments/:assignmentId/work-history", redirectTo: "assignments/:assignmentId/workHistory" },
    { path: "map/assignments/**", redirectTo: "assignments/**" },

    // Remain compatible with old map/parkingban route
    { path: "map/parkingban/:parkingBanId", component: ParkingBanRedirectComponent },
    { path: "map/parkingban/:parkingBanId/:tab", component: ParkingBanRedirectComponent },

    // Remain compatible with old map/task route
    { path: "map/task/:taskId", component: TaskRedirectComponent },
    { path: "map/task/:taskId/:tab", component: TaskRedirectComponent },

    //endregion

    {
        path: "status",
        component: ServiceStatusComponent,
        data: { name: "serviceStatus.title" },
    },
    {
        path: "about",
        loadChildren: () => import("./pages/about/about.routing").then((m) => m.ABOUT_ROUTES),
        data: { name: "about.title" },
    },
    {
        path: "administration",
        loadChildren: () =>
            import("./pages/administration/administration.routing").then((m) => m.ADMINISTRATION_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "administration.title" },
    },
    {
        path: "assignments",
        loadChildren: () => import("./pages/assignments/assignments.routing").then((m) => m.ASSIGNMENTS_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "assignments.title" },
    },
    {
        path: "configuration",
        loadChildren: () => import("./pages/configuration/configuration.routing").then((m) => m.CONFIGURATION_ROUTES),
        data: { name: "management.title" },
    },
    {
        path: "data",
        loadChildren: () => import("./pages/data/data.routing").then((m) => m.DATA_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "reports.title" },
    },
    {
        path: "devices",
        loadChildren: () => import("./pages/devices/devices.routing").then((m) => m.DEVICES_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "devices.title" },
    },
    {
        path: "documentation",
        loadChildren: () => import("./pages/documentation/documentation.routing").then((m) => m.DOCUMENTATION_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "documentation.title" },
    },
    {
        path: "history",
        loadChildren: () => import("./pages/history/history.routing").then((m) => m.HISTORY_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "history.title" },
    },
    {
        path: "link",
        loadChildren: () => import("./pages/link/link.routing").then((m) => m.LINK_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "manageLink.title" },
    },
    {
        path: "locations",
        loadChildren: () => import("./pages/installations/installations.routing").then((m) => m.INSTALLATION_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "locations.title" },
    },
    {
        path: "groups",
        loadChildren: () => import("./pages/groups/groups.routing").then((m) => m.GROUPS_ROUTES),
        data: { name: "map.title" },
    },
    {
        path: "notifications",
        loadChildren: () => import("./pages/notifications/notifications.routing").then((m) => m.NOTIFICATIONS_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "notifications.title" },
    },
    {
        path: "planning",
        loadChildren: () =>
            import("./pages/planning/assignment-planning.routing").then((m) => m.ASSIGNMENTS_PLANNING_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "assignmentPlanning.title" },
    },
    {
        path: "qr",
        loadChildren: () => import("./pages/qr/qr.routing").then((m) => m.QR_ROUTES),
        data: { name: "qr.title" },
    },
    {
        path: "reports",
        loadChildren: () => import("./pages/reports/reporting.routing").then((m) => m.REPORTING_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "reports.title" },
    },
    {
        path: "scenarios",
        loadChildren: () => import("./pages/scenarios/scenarios.routing").then((m) => m.SCENARIOS_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "scenarios.title" },
    },
    {
        path: "worklists",
        loadChildren: () => import("./pages/worklists/worklists.routing").then((m) => m.WORKLISTS_ROUTES),
        canActivate: [AuthGuard],
        data: { name: "worklists.title" },
    },
    {
        path: "**",
        redirectTo: "locations",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: "ignore" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
