import { Component, Input } from "@angular/core";
import { IAuditTrail } from "@ramudden/models/audit-trail";

@Component({
    selector: "app-audit-trail-details",
    templateUrl: "./audit-trail-details.component.html",
})
export class AuditTrailDetailsComponent {
    @Input() auditTrail: IAuditTrail;
}
