import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FileUtils } from "@ramudden/core/utils";
import { AttachmentCreator, IAttachment } from "@ramudden/models/attachment";
import { FilterOperator, SearchParameters } from "@ramudden/models/search";
import { AttachmentTypeApi } from "@ramudden/data-access/resource/attachment-type.api";
import { AttachmentApi } from "@ramudden/data-access/resource/attachment.api";
import { DownloadedFile, DownloadFileService } from "@ramudden/services";
import { ModalService } from "@ramudden/ui";
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";

@Component({
    selector: "app-photo-input",
    templateUrl: "./photo-input.component.html",
})
export class PhotoInputComponent implements OnInit, OnChanges {
    image: File;
    imagePreview: string;

    @ViewChild(ImageDialogComponent, { static: true }) imageDialogComponent: ImageDialogComponent;

    @Input() attachment: IAttachment;
    @Input() submitted: IAttachment;
    @Input() labelColSize = 2;
    @Input() colSize = 10;
    @Input() photoColSize = 17;
    @Input() required = true;
    @Input() popup = false;
    @Input() deleteImageMessageToTranslate: string;
    @Input() accepts = ["image/*"];

    @Output() photoDeleted = new EventEmitter<void>();

    private attachmentTypePhotoId: number;
    private existingPhoto: DownloadedFile;

    constructor(
        private readonly attachmentApi: AttachmentApi,
        private readonly attachmentTypeApi: AttachmentTypeApi,
        private readonly downloadFileService: DownloadFileService,
        private readonly modalService: ModalService,
        private readonly translate: TranslateService,
    ) {}

    acceptString() {
        return this.accepts.join(", ");
    }

    ngOnInit() {
        const photoSp = new SearchParameters();
        photoSp.filter = [{ field: "code", value: "Foto", operator: FilterOperator.equals }];

        this.attachmentTypeApi.search$(photoSp).subscribe((result) => {
            this.attachmentTypePhotoId = result.data && result.data.length > 0 ? result.data[0].id : undefined;
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        const attachmentChange = changes["attachment"];
        if (attachmentChange) {
            this.imagePreview = undefined;
            await this.handleAttachmentChange();
        }
    }

    setPhotoColSize() {
        const styles = {
            "margin-left": this.photoColSize + "%",
        };
        return styles;
    }

    async handleAttachmentChange() {
        let photo;

        if (this.attachment) {
            photo = await this.downloadFileService.downloadBlob(this.attachment.url);
        }

        this.existingPhoto = photo;
        await this.setImageDirectly(photo);
    }

    async setImage(event: { files: FileList }) {
        if (!event || event.files.length < 0) return;
        await this.setImageDirectly(event.files[0]);
    }

    async setImageDirectly(file: File) {
        this.image = file;
        this.imagePreview = this.image ? await FileUtils.toBase64(this.image) : null;
    }

    async uploadPhoto(creator: AttachmentCreator): Promise<IAttachment> {
        if (!this.image) return;

        creator.typeId = this.attachmentTypePhotoId;
        return await this.attachmentApi.replace$(
            this.attachment,
            this.existingPhoto,
            this.imagePreview,
            creator,
            this.image,
        );
    }

    setPhotoDeleted() {
        const onDelete = () => {
            this.imagePreview = undefined;
            this.image = undefined;
            this.photoDeleted.emit();
        };

        const modalBody = this.translate.instant(this.deleteImageMessageToTranslate, {
            fileName: this.image["file"]?.name ?? this.image.name,
        });
        this.modalService.delete(modalBody, onDelete);
    }

    async deletePhoto() {
        if (!this.attachment) return;
        await this.attachmentApi.delete$(this.attachment.id).toPromise();
    }

    onImageClick() {
        this.imageDialogComponent.create(this.image["file"]?.name ?? this.image.name, this.imagePreview);
    }
}
