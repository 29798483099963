import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DeviceUtils } from "@ramudden/core/utils";
import { BackendRights } from "@ramudden/models/backend-rights";
import { IDevice } from "@ramudden/models/device";
import { IMeasuringPoint } from "@ramudden/models/measuring-point";
import { SearchParameters, ServiceRequestOptions } from "@ramudden/models/search";
import { IUpload, IUploadDetailsDialogData, ProcessStatus } from "@ramudden/models/upload";
import { UploadApi } from "@ramudden/data-access/resource/upload.api";
import { MeasuringPointDataDeleteDialogComponent } from "../measuring-point-data-delete-dialog/measuring-point-data-delete.dialog";
import { ColumnType, ColumnVisibility, LazyTableComponent, TableColumn, TableService } from "../table/table.component";
import { UploadDetailsDialogComponent } from "../upload-details-dialog/upload-details.dialog";

@Component({
    selector: "app-uploads",
    templateUrl: "./uploads.component.html",
    styleUrl: "./uploads.component.scss",
})
export class UploadsComponent extends LazyTableComponent<IUpload> implements OnChanges {
    @ViewChild(MeasuringPointDataDeleteDialogComponent, { static: true })
    measuringPointDataDeleteDialog: MeasuringPointDataDeleteDialogComponent;
    @ViewChild(UploadDetailsDialogComponent, { static: true }) uploadDetailsDialog: UploadDetailsDialogComponent;

    @Input() measuringPoint?: IMeasuringPoint;
    @Input() device?: IDevice;
    @Input() deleteCommand = true;
    @Input() allowWithoutInput: boolean;

    deviceColumn: TableColumn;

    constructor(
        elementRef: ElementRef,
        uploadApi: UploadApi,
        tableService: TableService,
        readonly translateService: TranslateService,
    ) {
        super("uploads.component", elementRef, uploadApi, tableService);

        this.stretchHeight = true;

        const processStatusColumn = new TableColumn("statusId", "general.status", { type: ColumnType.Icon, width: 96 });
        this.addColumn(processStatusColumn);

        this.addColumn(new TableColumn("createDate", "uploads.createDate", { width: 160 }));
        this.addColumn(
            new TableColumn("fileSize", "general.fileSize", { visibility: ColumnVisibility.HideCompact, width: 133 }),
        );

        // this.measuringPointColumn = new TableColumn("measuringPoint", "general.measuringPoint", { filterType: FilterType.None, visibility: ColumnVisibility.HideCompact, width: 250 });
        // this.addColumn(this.measuringPointColumn);

        this.deviceColumn = new TableColumn("device", "general.device", { width: 150 });
        this.addColumn(this.deviceColumn);

        this.addColumn(
            new TableColumn("vehicleCount", "uploads.vehicleCount", {
                visibility: ColumnVisibility.HideCompact,
                width: 120,
            }),
        );
        this.addColumn(new TableColumn("pulseCount", "uploads.pulseCount", { width: 80 }));

        this.addColumn(new TableColumn("firstData", "uploads.firstData", { width: 160 }));
        this.addColumn(new TableColumn("lastData", "uploads.lastData", { width: 160 }));

        this.addColumn(new TableColumn("firstVehicle", "uploads.firstVehicle", { width: 160 }));
        this.addColumn(new TableColumn("lastVehicle", "uploads.lastVehicle", { width: 160 }));
        this.addColumn(
            new TableColumn("filename", "general.file", {
                visibility: ColumnVisibility.NeverHide,
                width: 400,
                resizable: false,
            }),
        );
        this.addColumn(
            new TableColumn("creator", "general.uploader", { visibility: ColumnVisibility.HideCompact, width: 220 }),
        );

        this.subscriptionManager.add(
            "onSelect",
            this.selected.subscribe((upload: IUpload) => {
                this.handleOnRowSelect(upload);
            }),
        );

        this.registerCommand({
            text: "form.delete",
            icon: "delete",
            click: (upload) => this.deleteData(upload),
            validFunc: () => this.deleteCommand && this.rights?.hasBackendRight(BackendRights.DeleteUpload),
            rowValidFunc: (upload) => !!upload.measuringPoint,
        });
    }

    override ngOnChanges(changes: SimpleChanges): void {
        const measuringPointChange = changes["measuringPoint"];
        if (measuringPointChange) {
            this.setMeasuringPoint(this.measuringPoint);
        }

        const deviceChange = changes["device"];
        if (deviceChange) {
            this.setDevice(this.device);
        }

        super.ngOnChanges(changes);
    }

    setMeasuringPoint(measuringPoint: IMeasuringPoint) {
        this.device = null;
        this.deviceColumn.hidden = false;

        this.measuringPoint = measuringPoint;
        // this.measuringPointColumn.hidden = true;

        this.updateRelevantColumns();
        this.loadTableRows();
    }

    setDevice(device: IDevice) {
        this.device = device;
        this.deviceColumn.hidden = true;

        this.measuringPoint = null;
        // this.measuringPointColumn.hidden = false;

        this.updateRelevantColumns();
        this.loadTableRows();
    }

    openDialog(upload: IUploadDetailsDialogData) {
        const onClose = () => {
            this.clearSelection();
        };

        this.uploadDetailsDialog.open(upload, onClose);
    }

    handleOnRowSelect(upload: IUpload) {
        this.openDialog(upload as IUploadDetailsDialogData);
    }

    override canLoad(): boolean {
        return this.allowWithoutInput || !!this.device || !!this.measuringPoint;
    }

    override getSearchParameters(): SearchParameters {
        const searchParameters = new SearchParameters();
        searchParameters.filter = [];
        searchParameters.queryParams = {};

        if (this.measuringPoint) {
            searchParameters.queryParams["MeasuringPointId"] = this.measuringPoint.id;
        }

        if (this.device) {
            searchParameters.queryParams["DeviceId"] = this.device.id;
        }

        return searchParameters;
    }

    override getServiceRequestOptions(): ServiceRequestOptions {
        const options = new ServiceRequestOptions();
        options.includes.add("Upload", "MeasuringPoint");
        options.includes.add("Upload", "Device");

        return options;
    }

    deleteData(upload: IUpload) {
        this.measuringPointDataDeleteDialog.openForUpload(upload, null, this.device);
    }

    getUploadStatusColor(upload: IUpload) {
        if (upload.statusId === ProcessStatus.Error) return "red";
        if (upload.statusId === ProcessStatus.Success) return "green";
        return "inherit";
    }

    getUploadStatusIcon(upload: IUpload) {
        if (upload.statusId === ProcessStatus.Error) return "error";
        if (upload.statusId === ProcessStatus.Success) return "checkmark";
        return null;
    }

    getCellClass(rowData: IUpload, column: TableColumn): string {
        if (column.field === "tube1PulseRatio") {
            return DeviceUtils.getTubeRatioCellClass(rowData.tube1PulseRatio);
        }

        if (column.field === "skippedPulseRatio") {
            return DeviceUtils.getSkippedRatioCellClass(rowData.skippedPulseRatio);
        }

        if (column.field === "pulseConversionRate") {
            return DeviceUtils.getPulseConversionRateCellClass(rowData.pulseConversionRate);
        }

        return "";
    }
}
