<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="toast">
    @for (message of messages; track $index) {
        <div
            class="toast__content"
            [ngClass]="message.type"
            (click)="removeMessage(message)"
        >
            @if (message?.title) {
                <h3>{{ message.title }}</h3>
            }
            @if (message?.message) {
                <p>{{ message.message }}</p>
            }
        </div>
    }
</div>
