import { isDevMode } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

export interface IComponentCanDeactivate {
    canDeactivate: () => boolean | Promise<boolean> | Observable<boolean>;
}

export class PendingChangesGuard {
    canDeactivate(
        component: IComponentCanDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot,
    ): boolean | Promise<boolean> | Observable<boolean> {
        if (!component) {
            if (isDevMode()) {
                console.warn(
                    "PendingChangesGuard -> Component was null.",
                    { route: currentRoute, state: currentState },
                    nextState,
                );
            }

            return true;
        }

        // Always allow navigation if we're going to /status
        // It means the client has noticed server is offline,
        // and we're trying to show the status page
        // Blocking here is not advised, user will just get confused
        // He'll get frustrated either way (losing changes + force redirect vs losing changes + perhaps not noticing)
        if (nextState && nextState.url === "/status") {
            return true;
        }

        return component.canDeactivate();
    }
}
