import { Pipe, PipeTransform } from "@angular/core";
import moment, { Duration, isDuration } from "moment";
import { environment } from "../../../environments/environment";

// tries to format in the shortest possible way by ommitting the day if it is today
@Pipe({ name: "shortDateTime", standalone: true })
export class ShortDateTimePipe implements PipeTransform {
    transform(date: Date, includeSeconds = false) {
        const now = new Date();
        const isCurrentDay = date.getDate() === now.getDate();
        const isCurrentYear = date.getFullYear() === now.getFullYear();

        let formatString = isCurrentDay ? "HH:mm" : isCurrentYear ? "DD/MM HH:mm" : "DD/MM/yyyy HH:mm";
        if (includeSeconds) formatString = formatString + ":ss";

        return moment(date).format(formatString);
    }
}

@Pipe({ name: "momentDateTime", standalone: true })
export class MomentDateTimePipe implements PipeTransform {
    transform(date: Date, day = true, seconds = false, _lang = "forceRefreshPipeTrick") {
        if (!date) return "";

        const formatString = (day ? "ddd " : "") + "L LT" + (seconds ? "S" : "");
        const result = moment(date).format(formatString);
        return result;
    }
}

@Pipe({ name: "momentDateAndTime", standalone: true })
export class MomentDateAndTimePipe implements PipeTransform {
    transform(date: Date) {
        if (!date) return "";
        const formatString = "yyyyMMDD_HHmmss";
        return moment(date).format(formatString);
    }
}

@Pipe({ name: "momentDate", standalone: true })
export class MomentDatePipe implements PipeTransform {
    transform(date: Date, day = true, _lang = "forceRefreshPipeTrick") {
        if (!date) return "";

        return moment(date).format(day ? "ddd L" : "L");
    }
}

@Pipe({ name: "momentTime", standalone: true })
export class MomentTimePipe implements PipeTransform {
    transform(date: Date, seconds = false) {
        if (!date) return "";

        return moment(date).format(seconds ? "LTS" : "LT");
    }
}

@Pipe({ name: "momentDuration", standalone: true })
export class MomentDurationPipe implements PipeTransform {
    transform(date: Date | Duration, seconds = false) {
        if (!date) return "";

        if (date instanceof Date) {
            return moment(date).format(seconds ? "LTS" : "LT");
        }

        if (isDuration(date)) {
            return moment.utc(date.as("milliseconds")).format(seconds ? "HH:mm:ss" : "HH:mm");
        }

        if (!environment.production) {
            console.warn("MomentDurationPipe: Couldn't map to duration", date);
        }

        return `${date}`;
    }
}

@Pipe({ name: "momentRelativeTime", standalone: true })
export class MomentRelativeTimePipe implements PipeTransform {
    transform(date: Date, _lang = "forceRefreshPipeTrick") {
        if (!date) return "";

        const result = moment(date).fromNow();
        return result;
    }
}
