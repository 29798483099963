import { ChangeDetectorRef, Directive, inject, Input, OnDestroy, ViewChild } from "@angular/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { ResizeService } from "@ramudden/services";
import { Dialog } from "primeng/dialog";

@Directive()
export abstract class DialogComponentBase implements OnDestroy {
    @ViewChild(Dialog, { static: true }) dialog: Dialog;

    @Input() blockBackground = true;
    @Input() draggable = true;
    @Input() dismissOnClickOutside = false;
    @Input() minX = -600;
    @Input() minY = -400;
    @Input() contentStyle: any = {};
    @Input() appendTo: "body" | any = "body";

    protected subscriptionManager = new SubscriptionManager();
    private resizeService: ResizeService = inject(ResizeService);
    private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    body: HTMLDivElement;
    visible: boolean;

    constructor() {
        const resizeSub = this.resizeService.onResize.subscribe((_) => {
            if (!this.visible || !this.dialog || !this.dialog.container) return;
            this.dialog.center();
        });
        this.subscriptionManager.add("resizeSub", resizeSub);
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    protected canOpen(): boolean {
        return true;
    }

    protected onOpen() {}

    protected afterShow() {}

    // PrimeNG trigger upon visible = true
    onShow() {
        this.body = this.dialog.el.nativeElement.getElementsByClassName("p-dialog-content")[0];
        this.afterShow();
    }

    // PrimeNG trigger upon visible = false
    onHide() {
        this.onClose();
        this.body = null;
    }

    protected openDialog() {
        if (!this.canOpen()) return;

        this.onOpen();
        this.visible = true;

        // The code that executes this might use ChangeDetectionStrategy.OnPush, so we need to notify the change detector
        this.cdr.markForCheck();
    }

    protected onClose() {}

    close() {
        this.visible = false;
        this.cdr.markForCheck();
    }

    onCancel() {
        this.close();
    }
}
