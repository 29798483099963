import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationService } from "./configuration.service";

// We want to load the google apis using async / defer
// And we want our code to know when it's done loading
// So we create the script tag ourselves, and return a promise

@Injectable({
    providedIn: "root",
})
export class GoogleMapsLoaderService {
    private scriptId = "signcoserv-google-maps";
    private promise: Promise<string>;

    private _isLoaded = false;
    get isLoaded(): boolean {
        return this._isLoaded;
    }

    constructor(
        private readonly translateService: TranslateService,
        private readonly configurationService: ConfigurationService,
    ) {}

    load(): Promise<string> {
        // First time 'load' is called?
        if (!this.promise) {
            const callback = "__onGoogleLoaded";
            const apiKey = this.configurationService.configuration.googleMapsApiKey;
            const language = this.translateService.currentLang;

            const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.58&callback=${callback}&language=${language}&libraries=places,geometry,marker`;

            // Make promise to load
            this.promise = new Promise<string>((resolve) => {
                const hasScript = this.getCurrentScript();
                if (hasScript) {
                    // During HMR
                    resolve("google maps already loaded");
                    return;
                }

                // Set callback for when google maps is loaded.
                window[callback] = () => {
                    this._isLoaded = true;
                    resolve("google maps api loaded");
                };

                const node = document.createElement("script");
                node.id = this.scriptId;
                node.async = true;
                node.defer = true;
                node.src = url;
                node.nonce = "mapsnonce";
                node.type = "text/javascript";
                document.head.appendChild(node);
            });
        }

        // Always return promise. When 'load' is called many times, the promise is already resolved.
        return this.promise;
    }

    reload(): Promise<string> {
        const currentScript = this.getCurrentScript();
        if (currentScript) {
            currentScript.parentNode.removeChild(currentScript);
        }

        return this.load();
    }

    private getCurrentScript(): HTMLElement {
        return document.getElementById(this.scriptId);
    }
}
