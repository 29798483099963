import { Component, HostListener, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ViewModelEnum } from "@ramudden/models/domain-data";
import { ProgressAction, ProgressService, ToastService, UploadFileService, UploadedFile } from "@ramudden/services";
import { DomainData, DomainDataService } from "../../../services/domain-data.service";
import { NavigationService } from "../../../services/navigation.service";

@Component({
    selector: "app-progress",
    templateUrl: "./progress.component.html",
})
export class ProgressComponent implements OnInit {
    visible: boolean;
    expanded: boolean;
    clearing: boolean;
    historyReportsUrl = "";

    files = new Array<UploadedFile>();
    actions = new Array<ProgressAction>();

    totalProgress: number;
    hasError = false;

    reportTypeViewModels: ViewModelEnum[];

    constructor(
        readonly translateService: TranslateService,
        readonly domainDataService: DomainDataService,
        readonly uploadFileService: UploadFileService,
        readonly progressService: ProgressService,
        readonly navigationService: NavigationService,
        readonly toastService: ToastService,
    ) {
        this.uploadFileService.subscribeFiles("progress-component", (files) => this.updateFiles(files));
        this.uploadFileService.subscribeProgress("progress-component", () => this.calculateTotalProgress());
        this.uploadFileService.subscribeError("progress-component", (_) => this.handleError());

        this.progressService.subscribeActions("progress-component", (actions) => this.updateActions(actions));
        this.progressService.subscribeProgress("progress-component", () => this.calculateTotalProgress());
        this.progressService.subscribePendingAction("progress-component", (action) =>
            this.notifyAboutPendingActions(action),
        );
        this.progressService.subscribeError("progress-component", (_) => this.handleError());

        this.historyReportsUrl = this.navigationService.getHistoryReportsUrl();
    }
    ngOnInit(): void {
        this.loadDomainData();
    }
    @HostListener("window:beforeunload")
    windowBeforeUnload() {
        // on false, shows "are you sure? changes will be lost" in the browser window
        return this.canClose();
    }

    private loadDomainData() {
        this.domainDataService.get(DomainData.ReportTypes).then((reportTypeViewModels) => {
            this.reportTypeViewModels = reportTypeViewModels;
        });
    }

    private calculateTotalProgress() {
        let totalProgress = 0;
        let queueLength = 0;
        this.hasError = false;

        this.visible = !this.clearing && this.files.length + this.actions.length > 0;

        this.files.forEach((file) => {
            if (file.error) {
                this.hasError = true;
                return;
            }

            if (file.progress >= 100) return;

            totalProgress += file.progress;
            queueLength++;
        });

        this.actions.forEach((action) => {
            if (!action.lastProgress) {
                // Didn't get an update, but is queuing
                queueLength++;
                return;
            }

            if (action.lastProgress.hasError) {
                this.hasError = true;
                return;
            }

            if (action.lastProgress.progress >= 100) return;

            totalProgress += action.lastProgress.progress;
            queueLength++;
        });

        this.totalProgress = queueLength ? Math.round(totalProgress / Math.max(1, queueLength)) : 100; // Show 100% when completely done
    }

    private handleError() {
        if (!this.expanded) {
            this.toggleExpand();
        }
    }

    //#region Upload Files

    private updateFiles(files: UploadedFile[]) {
        if (files.length > 5) {
            files = files.slice(files.length - 5);
        }

        this.files = files;
        this.calculateTotalProgress();

        if (!this.expanded) {
            this.toggleExpand();
        }
    }

    clearFiles() {
        this.uploadFileService.clearCompletedFiles();
    }

    removeFile(file: UploadedFile) {
        this.uploadFileService.removeFile(file);
    }

    retryUpload(file: UploadedFile) {
        this.uploadFileService.retry(file);
    }

    //#endregion Upload Files

    //#region Progress Actions

    private updateActions(actions: ProgressAction[]) {
        this.actions = actions;
        this.calculateTotalProgress();

        if (!this.expanded) {
            this.toggleExpand();
        }
    }

    notifyAboutPendingActions(action: ProgressAction) {
        if (!action) {
            return;
        }

        const message = this.translateService.instant("reports.progress.waitingInTheQueue", {
            name: this.getActionLabel(action),
        });
        this.toastService.info(message, null, { disableTimeOut: false, timeOut: 3000 });
    }

    clearActions() {
        this.progressService.clearCompletedActions();
    }

    removeAction(action: ProgressAction) {
        this.progressService.removeAction(action);
    }

    cancelAction(action: ProgressAction) {
        this.progressService.cancelAction(action);
    }

    isActionWorking(action: ProgressAction) {
        return !action.lastProgress || (!action.lastProgress.hasError && !action.lastProgress.isDone);
    }

    getActiveActions(): ProgressAction[] {
        return this.actions.filter((x) => this.isActionWorking(x));
    }

    getActionLabel(action: ProgressAction): string {
        if (this.reportTypeViewModels) {
            const reportType = this.reportTypeViewModels.find((x) => x.jsonValue === action.name);
            if (reportType) {
                return reportType.label;
            }
        } else {
            this.loadDomainData();
        }

        return this.translateService.instant(action.name);
    }

    progressActionTrackByFn(index: number, item: ProgressAction) {
        return item.id;
    }

    //#endregion ProgressActions

    clear() {
        this.clearing = true;
        this.clearFiles();
        this.clearActions();
        this.clearing = false;
    }

    isWorking(): boolean {
        return this.uploadFileService.isUploadInProgress() || this.progressService.isWorking();
    }

    toggleExpand() {
        this.expanded = !this.expanded;
    }

    canClose(): boolean {
        // Only file uploads should hinder ability to close
        const validFiles = this.files.filter((x) => !x.error);
        const validCompletedFiles = validFiles.filter((x) => x.completed);
        return validFiles.length === validCompletedFiles.length;
    }

    close() {
        this.visible = false;
        this.expanded = false;
        this.clear();
    }
}
