import { Injectable } from "@angular/core";
import { DatePeriodSetCreator, IDatePeriodSet } from "@ramudden/models/date-period-set";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class DatePeriodSetApi extends ApiService<IDatePeriodSet, DatePeriodSetCreator, DatePeriodSetCreator> {
    override getRoute(): string {
        return "DatePeriodSets";
    }
}
