/// <reference types="@types/google.maps" />

import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MomentDatePipe, MomentDateTimePipe, MomentDurationPipe, MomentTimePipe } from "./pipes/datetime.pipe";

import { AutoCompleteModule } from "primeng/autocomplete";
import { BadgeModule } from "primeng/badge";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { ColorPickerModule } from "primeng/colorpicker";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { InputSwitchModule } from "primeng/inputswitch";
import { MultiSelectModule } from "primeng/multiselect";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { ProgressBarModule } from "primeng/progressbar";
import { SliderModule } from "primeng/slider";
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeSelectModule } from "primeng/treeselect";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";

import { AngularDraggableModule } from "angular2-draggable";

import { PdfViewerModule } from "ng2-pdf-viewer";

import { BytePipe } from "./pipes/byte.pipe";
import { ReadableDeviceDisplayRulePipe } from "./pipes/readable-device-display-rule.pipe";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { ThousandSeperatorPipe } from "./pipes/thousand-seperator.pipe";
import { TranslateModelPipe } from "./pipes/translate-model.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";

import { AutofocusDirective } from "./directives/autofocus.directive";
import { DebounceDirective } from "./directives/debounce.directive";
import { ScrollDragDirective } from "./directives/scroll-drag.directive";

import { AttachmentFormComponent } from "./components/attachment-form/attachment-form.component";
import { AttachmentOverviewComponent } from "./components/attachment-overview/attachment-overview.component";
import { AuditTrailDetailsComponent } from "./components/audit-trail-details/audit-trail-details.component";
import { CameraControlsComponent } from "./components/camera-controls/camera-controls.component";
import { CodeHighlightComponent } from "./components/code-highlight/code-highlight.component";
import { DomainDataFieldComponent } from "./components/domain-data-field/domain-data-field.component";
import { ExpandoComponent } from "./components/expando/expando.component";
import { FilesComponent } from "./components/files/files.component";
import { FormValidationComponent } from "./components/form-validation/form-validation.component";
import { GalleriaComponent } from "./components/galleria/galleria.component";
import { IconButtonComponent } from "./components/icon-button/icon-button.component";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { ModelTranslationFormComponent } from "./components/model-translation-form/model-translation-form.component";
import { NavigatorComponent } from "./components/navigator/navigator.component";
import { ProgressComponent } from "./components/progress/progress.component";
import { RoundSliderComponent } from "./components/round-slider/round-slider.component";
import { ShowMoreComponent } from "./components/show-more/show-more.component";
import { ChartComponent } from "./components/signco-chart/signco-chart.component";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { TableFilterComponent, TableFilterPreviewComponent } from "./components/table/table.component";
import { TextDialogComponent } from "./components/text-dialog/text.dialog";
import { UploadDetailsDialogComponent } from "./components/upload-details-dialog/upload-details.dialog";

import { ApplicationCreateResultDialogComponent } from "./components/application-create-result-dialog/application-create-result.dialog";
import { AuditTrailsComponent } from "./components/audit-trails/audit-trails.component";
import { GroupMeasuringPointsComponent } from "./components/group-measuring-points/group-measuring-points.component";
import { JournalComponent } from "./components/journal/journal.component";
import { MeasuringPointDayChangeOverviewComponent } from "./components/measuring-point-day-change-overview/measuring-point-day-change-overview.component";
import { MeasuringPointsComponent } from "./components/measuring-points/measuring-points.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { UploadsComponent } from "./components/uploads/uploads.component";
import { UsersComponent } from "./components/users/users.component";

import { NgxColorsModule } from "ngx-colors";
import { AssignmentSubtasksComponent } from "./components/assignment-subtasks/assignment-subtasks.component";
import { GeneralDialogComponent } from "./components/dialog/general-dialog.component";
import { FuzzySearchInputComponent } from "./components/fuzzy-search-input/fuzzy-search-input.component";
import { ImageDialogComponent } from "./components/image-dialog/image-dialog.component";
import { JournalDialogComponent } from "./components/journal-dialog/journal.dialog";
import { JsonEditorComponent } from "./components/json-editor/json-editor.component";
import { LegendComponent } from "./components/legend/legend.component";
import { LinksComponent } from "./components/links/links.component";
import { ManageApplicationDialogComponent } from "./components/manage-application-dialog/manage-application.dialog";
import { ManageUserDialogComponent } from "./components/manage-user-dialog/manage-user.dialog";
import { MeasuringPointDataDeleteDialogComponent } from "./components/measuring-point-data-delete-dialog/measuring-point-data-delete.dialog";
import { PhotoInputComponent } from "./components/photo-input/photo-input.component";
import { ResourceSelectorComponent } from "./components/resource-selector/resource-selector.component";
import { ScannerDialogComponent } from "./components/scanner-dialog/scanner-dialog.component";
import { SelectDevicesComponent } from "./components/select-devices/select-devices.component";
import { SelectGroupsComponent } from "./components/select-groups/select-groups.component";
import { SelectMeasuringPointsComponent } from "./components/select-measuring-points/select-measuring-points.component";
import { SelectOrganizationsComponent } from "./components/select-organizations/select-organizations.component";
import { SelectProjectsComponent } from "./components/select-projects/select-projects.component";
import { SelectScenariosComponent } from "./components/select-scenarios/select-scenarios.component";
import { SvgIconBadgeComponent } from "./components/svg-icon-badge/svg-icon-badge.component";
import { TasksComponent } from "./components/tasks/tasks.component";
import { TimelineActivityComponent } from "./components/timeline-activity/timeline-activity.component";
import { TimelineChartComponent } from "./components/timeline-chart/timeline-chart.component";
import { TwoLevelSelectComponent } from "./components/two-level-select/two-level-select.component";
import { UploadMetricComponent } from "./components/upload-metric/upload-metric.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { FileDragAndDropOutsideNgZoneDirective } from "./directives/file-drag-and-drop-outside-ngzone.directive";
import { FileDragAndDropDirective } from "./directives/file-drag-and-drop.directive";
import { TextFieldValidatorDirective } from "./directives/text-field-validator.directive";
import { AddressPipe } from "./pipes/address.pipe";
import { AssignmentNamePipe } from "./pipes/assignment-name.pipe";
import { CoordinatePipe } from "./pipes/coordinate.pipe";
import { DataSetNamePipe } from "./pipes/data-set-name.pipe";
import { DateRangePipe } from "./pipes/date-range.pipe";
import { ShortenMinutesStringPipe } from "./pipes/shorten-minutes-string.pipe";
import { TranslateDeviceTypeRulesPipe } from "./pipes/translate-device-type-rules.pipe";
import { TranslateEnumListPipe } from "./pipes/translate-enum-list.pipe";
import { TranslateEnumPipe } from "./pipes/translate-enum.pipe";
import { TranslateFilterTypePipe } from "./pipes/translate-filter-type-pipe";
import { TranslateMeasuringPointStatusPipe } from "./pipes/translate-measuring-point-status.pipe";
import { TranslateMessagePipe } from "./pipes/translate-message.pipe";
import { TranslateValidationPipe } from "./pipes/translate-validation.pipe";
import { VehiclePipe } from "./pipes/vehicle.pipe";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        HttpClientModule,
        ScrollingModule,
        TableModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        SliderModule,
        MultiSelectModule,
        TooltipModule,
        CheckboxModule,
        TriStateCheckboxModule,
        ProgressBarModule,
        ColorPickerModule,
        InputSwitchModule,
        DialogModule,
        PaginatorModule,
        TreeModule,
        TreeSelectModule,
        AngularDraggableModule,
        PdfViewerModule,
        NgxColorsModule,
        MultiSelectModule,
        BadgeModule,
        DataViewModule,
        OverlayPanelModule,
        MomentDateTimePipe,
        MomentDatePipe,
        MomentTimePipe,
        MomentDurationPipe,
    ],
    declarations: [
        AddressPipe,
        CoordinatePipe,
        DataSetNamePipe,
        SafeHtmlPipe,
        BytePipe,
        ThousandSeperatorPipe,
        TranslateModelPipe,
        TranslateEnumPipe,
        TranslateEnumListPipe,
        TranslateValidationPipe,
        TranslateFilterTypePipe,
        TranslateMeasuringPointStatusPipe,
        ReadableDeviceDisplayRulePipe,
        TruncatePipe,
        AutofocusDirective,
        DebounceDirective,
        ScrollDragDirective,
        TextFieldValidatorDirective,
        ClickOutsideDirective,
        FileDragAndDropOutsideNgZoneDirective,
        ApplicationCreateResultDialogComponent,
        AttachmentFormComponent,
        AttachmentOverviewComponent,
        AuditTrailDetailsComponent,
        AuditTrailsComponent,
        CameraControlsComponent,
        ChartComponent,
        CodeHighlightComponent,
        DomainDataFieldComponent,
        ExpandoComponent,
        FilesComponent,
        FormValidationComponent,
        GalleriaComponent,
        GeneralDialogComponent,
        GroupMeasuringPointsComponent,
        IconButtonComponent,
        JournalComponent,
        JournalDialogComponent,
        LoadingSpinnerComponent,
        ManageApplicationDialogComponent,
        ManageUserDialogComponent,
        MeasuringPointDataDeleteDialogComponent,
        MeasuringPointDayChangeOverviewComponent,
        MeasuringPointsComponent,
        ModelTranslationFormComponent,
        NavigatorComponent,
        ProgressComponent,
        ReportsComponent,
        RoundSliderComponent,
        SelectDevicesComponent,
        SelectGroupsComponent,
        SelectMeasuringPointsComponent,
        SelectProjectsComponent,
        ShowMoreComponent,
        SvgIconComponent,
        TableFilterComponent,
        TableFilterPreviewComponent,
        TextDialogComponent,
        UploadDetailsDialogComponent,
        UploadsComponent,
        UsersComponent,
        AssignmentSubtasksComponent,
        TasksComponent,
        ImageDialogComponent,
        PhotoInputComponent,
        FuzzySearchInputComponent,
        TwoLevelSelectComponent,
        ShortenMinutesStringPipe,
        LegendComponent,
        AssignmentNamePipe,
        FileDragAndDropDirective,
        VehiclePipe,
        TranslateDeviceTypeRulesPipe,
        ResourceSelectorComponent,
        SelectOrganizationsComponent,
        SelectScenariosComponent,
        JsonEditorComponent,
        ScannerDialogComponent,
        UploadMetricComponent,
        LinksComponent,
        SvgIconBadgeComponent,
        TranslateMessagePipe,
        DateRangePipe,
        TimelineActivityComponent,
        TimelineChartComponent,
    ],
    exports: [
        AddressPipe,
        CoordinatePipe,
        AssignmentNamePipe,
        SafeHtmlPipe,
        BytePipe,
        ThousandSeperatorPipe,
        TranslateModelPipe,
        TranslateEnumPipe,
        TranslateEnumListPipe,
        TranslateValidationPipe,
        TranslateFilterTypePipe,
        TranslateMeasuringPointStatusPipe,
        ReadableDeviceDisplayRulePipe,
        TruncatePipe,
        DataSetNamePipe,
        VehiclePipe,
        TranslateDeviceTypeRulesPipe,
        AutofocusDirective,
        DebounceDirective,
        ScrollDragDirective,
        TextFieldValidatorDirective,
        ClickOutsideDirective,
        FileDragAndDropDirective,
        FileDragAndDropOutsideNgZoneDirective,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        HttpClientModule,
        ScrollingModule,
        TableModule,
        AutoCompleteModule,
        DropdownModule,
        CalendarModule,
        SliderModule,
        MultiSelectModule,
        TooltipModule,
        CheckboxModule,
        TriStateCheckboxModule,
        ProgressBarModule,
        ColorPickerModule,
        InputSwitchModule,
        DialogModule,
        PaginatorModule,
        AngularDraggableModule,
        TreeModule,
        TreeSelectModule,
        PdfViewerModule,
        NgxColorsModule,
        ApplicationCreateResultDialogComponent,
        AttachmentFormComponent,
        AttachmentOverviewComponent,
        AuditTrailDetailsComponent,
        AuditTrailsComponent,
        CameraControlsComponent,
        ChartComponent,
        CodeHighlightComponent,
        DomainDataFieldComponent,
        ExpandoComponent,
        FilesComponent,
        FormValidationComponent,
        GalleriaComponent,
        GeneralDialogComponent,
        GroupMeasuringPointsComponent,
        IconButtonComponent,
        JournalComponent,
        JournalDialogComponent,
        LoadingSpinnerComponent,
        ManageApplicationDialogComponent,
        ManageUserDialogComponent,
        MeasuringPointDataDeleteDialogComponent,
        MeasuringPointDayChangeOverviewComponent,
        MeasuringPointsComponent,
        ModelTranslationFormComponent,
        NavigatorComponent,
        ProgressComponent,
        ReportsComponent,
        RoundSliderComponent,
        ResourceSelectorComponent,
        SelectDevicesComponent,
        SelectGroupsComponent,
        SelectMeasuringPointsComponent,
        SelectProjectsComponent,
        ShowMoreComponent,
        SvgIconComponent,
        TableFilterComponent,
        TableFilterPreviewComponent,
        TextDialogComponent,
        UploadDetailsDialogComponent,
        UploadsComponent,
        UsersComponent,
        AssignmentSubtasksComponent,
        TasksComponent,
        PhotoInputComponent,
        ImageDialogComponent,
        FuzzySearchInputComponent,
        LegendComponent,
        TwoLevelSelectComponent,
        JsonEditorComponent,
        ScannerDialogComponent,
        UploadMetricComponent,
        LinksComponent,
        TranslateMessagePipe,
        BadgeModule,
        DataViewModule,
        OverlayPanelModule,
        SvgIconBadgeComponent,
        DateRangePipe,
        TimelineActivityComponent,
        TimelineChartComponent,
        SelectOrganizationsComponent,
        SelectScenariosComponent,
    ],
})
export class SharedModule {}
