import { Injectable } from "@angular/core";
import { BikeParameterSetCreator, IBikeParameterSet } from "@ramudden/models/bike-parameter-set";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class BikeParameterSetApi extends ApiService<
    IBikeParameterSet,
    BikeParameterSetCreator,
    BikeParameterSetCreator
> {
    override getRoute(): string {
        return "BikeParameterSets";
    }
}
