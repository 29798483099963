import { CommonModule } from "@angular/common";
import { Component, Inject, input, OnInit } from "@angular/core";
import { APP_CONFIGURATION, AppConfiguration } from "@ramudden/core/constants";
import { IconName } from "./svg.model";

@Component({
    selector: "m-svg",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./svg.component.html",
    styleUrl: "./svg.component.scss",
})
export class SvgComponent implements OnInit {
    protected href: string;

    iconName = input.required<IconName>();
    toolTip = input<string>();

    constructor(@Inject(APP_CONFIGURATION) public config: AppConfiguration) {}

    ngOnInit() {
        this.setHref();
    }

    setHref() {
        this.href = `icons.svg?v=${this.config.svgVersion}#${this.iconName()}`;
    }
}
