import { Injectable } from "@angular/core";
import { SigncoError } from "@ramudden/models/error";
import { Observable } from "rxjs";
import { ApiServiceBase } from "./api";

@Injectable({
    providedIn: "root",
})
export class ErrorApi extends ApiServiceBase {
    override getRoute(): string {
        return "Errors";
    }

    create$(error: string): Observable<void> {
        return this.http.post<void>(this.getUrl(), new SigncoError(error));
    }
}
