import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { IUpload, UploadCreator } from "@ramudden/models/upload";
import { DownloadedFile, DownloadFileService } from "@ramudden/services";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class UploadApi extends ApiService<IUpload, UploadCreator, UploadCreator> {
    protected readonly downloadFileService = inject(DownloadFileService);

    override getRoute(): string {
        return "Uploads";
    }

    downloadAnalysis$(upload: IUpload): Promise<DownloadedFile> {
        const url = `${this.getUrl()}/${upload.id}/analysis`;
        return this.downloadFileService.downloadBlob(url);
    }

    uploadBulk$(file: File): Promise<IUpload[]> {
        const url = `${super.getUrl()}/Upload`;
        const formData = new FormData();
        formData.append("file", file);

        return new Promise<IUpload[]>((resolve, reject) => {
            const onSuccess = (uploads: IUpload[]) => {
                resolve(uploads);
            };

            const onError = async (error: HttpErrorResponse) => {
                reject(error);
            };

            this.http.post<IUpload[]>(url, formData).subscribe(onSuccess, onError);
        });
    }
}
