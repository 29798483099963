import {
    ComponentRef,
    createComponent,
    EnvironmentInjector,
    inject,
    Injectable,
    Injector,
    ViewContainerRef,
} from "@angular/core";
import { Subject } from "rxjs";
import { ModalComponent, ModalConfig } from "../modal";

@Injectable({ providedIn: "root" })
export class ModalService {
    private envInjector = inject(EnvironmentInjector);
    private injector = inject(Injector);

    /**
     * Shows a modal and returns when the modal is closed. This immediately closes the modal when the handler is called.
     * @param config
     */
    public showModal(config: ModalConfig): void {
        const componentRef = this.createModal(config, null);
        componentRef.instance.close.subscribe((handler) => {
            this.destroyModal(componentRef);
            if (typeof handler === "function") handler();
        });
    }

    /**
     * Shows a modal and returns a promise that resolves when the modal is closed.
     * @param config
     */
    public showModalAsync(config: ModalConfig): Promise<any> {
        const resultSubject = new Subject<any>();
        const componentRef = this.createModal(config, resultSubject);

        return new Promise((resolve) => {
            resultSubject.subscribe((handler) => {
                this.destroyModal(componentRef);
                resolve(handler());
            });
        });
    }

    //region Utilities
    loginNotification(
        body: string,
        title = "general.info",
        onOk: (doNotShowAgain: boolean) => void = null,
    ): Promise<any> {
        const resultSubject = new Subject<any>();
        const config: ModalConfig = {
            title: title,
            body: body,
            type: "info",
            actions: [{ label: "Ok", handler: () => onOk, class: ["btn--yellow"] }],
        };
        const componentRef = this.createModal(config, resultSubject);
        return new Promise((resolve) => {
            resultSubject.subscribe((handler) => {
                this.destroyModal(componentRef);
                resolve(handler(componentRef.instance.doNotShowAgain));
            });
        });
    }

    public alert(body: string, title = "general.alert"): void {
        this.showModal({
            title,
            body,
            type: "alert",
            actions: [{ label: "Ok", handler: () => {}, class: ["btn--yellow"] }],
        });
    }

    public error(body: string, title = "general.error"): void {
        this.showModal({
            title,
            body,
            type: "error",
            actions: [{ label: "Ok", handler: () => {}, class: ["btn--yellow"] }],
        });
    }

    public confirm(
        body: string,
        onOk?: () => void,
        onCancel?: () => void,
        type: "info" | "alert" = "info",
        canClose = true,
    ): void {
        this.showModal({
            canClose: canClose,
            body: body,
            title: "general.info",
            type: type,
            actions: [
                { label: "Cancel", handler: onCancel, class: ["btn--lightgrey"] },
                { label: "Ok", handler: onOk, class: ["btn--yellow"] },
            ],
        });
    }

    public confirmAsync(body: string, type: "info" | "alert" = "info"): Promise<boolean> {
        return this.showModalAsync({
            body: body,
            title: "general.info",
            type: type,
            actions: [
                { label: "Cancel", handler: () => false, class: ["btn--lightgrey"] },
                { label: "Ok", handler: () => true, class: ["btn--yellow"] },
            ],
        });
    }

    public delete(body: string, onDelete: () => void, onCancel?: () => void): void {
        this.showModal({
            body: body,
            title: "form.delete",
            type: "delete",
            actions: [
                { label: "Cancel", handler: onCancel, class: ["btn--lightgrey"] },
                { label: "Delete", handler: onDelete, class: ["btn--yellow"] },
            ],
        });
    }
    //endregion

    //region Helpers
    private rootViewContainerRef!: ViewContainerRef;

    /**
     * Sets the root view container reference for the modal service.
     * @param viewContainerRef
     */
    public setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainerRef = viewContainerRef;
    }

    private createModal(config: ModalConfig, resultSubject: Subject<any> | null): ComponentRef<ModalComponent> {
        const componentRef = createComponent(ModalComponent, {
            environmentInjector: this.envInjector,
            elementInjector: this.injector,
        });

        componentRef.instance.config = config;

        if (resultSubject) {
            componentRef.instance.close.subscribe((handler) => {
                resultSubject.next(handler);
                resultSubject.complete();
            });
        }

        this.rootViewContainerRef.insert(componentRef.hostView);
        componentRef.instance.openModal();

        return componentRef;
    }

    private destroyModal(componentRef: ComponentRef<ModalComponent>) {
        componentRef.destroy();
    }
    //endregion
}
